<template>
  <Teleport v-if="isMounted" to="body">
    <transition :name="transitionName">
      <div
        v-if="isPanelOpen"
        class="fixed top-0 left-0 right-0 bg-ne-bg-2 overflow-y-auto"
        :style="{
          zIndex: hideBottomNavbar ? 10000000002 : 1000,
          height: hideBottomNavbar
            ? '100%'
            : `calc(100dvh - ${MOBILE_BOTTOM_NAVBAR_HEIGHT})`,
        }"
        @touchstart.stop.passive="touchStartHandler"
        @touchend.stop.passive="touchEndHandler"
      >
        <div class="flex flex-col h-full">
          <div v-if="type === 'default'" class="flex justify-center">
            <button
              class="text-black w-[134px] h-[24px] flex justify-center content-center items-center bg-ne-bg-5 rounded-b-md"
              @click="isPanelOpen = false"
            >
              <AppIcon name="chevron-bottom" />
            </button>
          </div>

          <div
            v-if="type === 'cross'"
            class="flex p-[12px]"
            :class="[returnable ? 'justify-between' : 'justify-end']"
          >
            <button
              v-if="returnable"
              class="p-[12px]"
              @click="isPanelOpen = false"
            >
              <AppIcon name="chevron-left" width="24px" height="24px" />
            </button>
            <button class="p-[12px]" @click="handleClose">
              <AppIcon name="close-16" width="24px" height="24px" />
            </button>
          </div>
          <div
            class="relative h-full"
            :class="[
              scroll ? 'overflow-y: scroll pb-ne-10' : 'overflow-y-hidden',
            ]"
          >
            <slot />
          </div>
        </div>
      </div>
    </transition>
  </Teleport>
</template>

<script setup lang="ts">
import {
  computed,
  defineProps,
  onMounted,
  PropType,
  provide,
  ref,
  watch,
} from 'vue'
import AppIcon from '@/components/app/AppIcon.vue'
import { IS_MOBILE_FULLSCREEN_PANEL_OPEN_KEY } from '@/layouts/DefaultLayout/MobileLayout/MobileFullscreenPanel/IS_MOBILE_FULLSCREEN_PANEL_OPEN_KEY'
import { useSafeInject } from '@/utils/useSafeInject'
import { useBodyOverflow } from '@/layouts/DefaultLayout/MobileLayout/useBodyOverflow'
import { MOBILE_BOTTOM_NAVBAR_HEIGHT } from '@/layouts/DefaultLayout/MobileLayout/MobileBottomNavBar/MOBILE_BOTTOM_NAVBAR_HEIGHT'

type TransitionKeys = 'bottomToTop' | 'rightToLeft' | 'opacity' | 'topToBottom'
type ButtonTypes = 'default' | 'cross'

const props = defineProps({
  hideBottomNavbar: Boolean,
  visible: Boolean,
  type: {
    type: String as PropType<ButtonTypes>,
    default: 'default',
  },
  transition: {
    type: String as PropType<TransitionKeys>,
    default: 'bottomToTop',
  },
  returnable: Boolean,
  touchable: {
    type: Boolean,
    default: false,
  },
  scroll: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['update:visible', 'close'])

const transitionNameMap = {
  bottomToTop: 'slideY',
  rightToLeft: 'slideX',
  opacity: 'fade',
  topToBottom: 'slideNegativeY',
}

const transitionName = computed(() => transitionNameMap[props.transition])
const isMounted = ref(false)

const isPanelOpen = computed({
  get() {
    return props.visible
  },
  set(value) {
    emit('update:visible', value)
  },
})

provide(IS_MOBILE_FULLSCREEN_PANEL_OPEN_KEY, isPanelOpen)

const isParentPanelOpen = useSafeInject(
  IS_MOBILE_FULLSCREEN_PANEL_OPEN_KEY,
  ref(false)
)

onMounted(() => {
  isMounted.value = true
})

function handleClose() {
  if (isParentPanelOpen.value) {
    isParentPanelOpen.value = false
  }
  isPanelOpen.value = false
  emit('close')
}

const isAnyPanelOpen = computed(() => {
  return isParentPanelOpen.value || isPanelOpen.value
})

useBodyOverflow(isAnyPanelOpen)

const translateX = ref(0)
const startX = ref(0)

const touchStartHandler = (e: TouchEvent) => {
  if (!props.touchable) return
  startX.value = e.touches[0].clientX
}

const touchEndHandler = (e: TouchEvent) => {
  if (!props.touchable) return
  const endX = e.changedTouches[0].clientX

  if (endX - startX.value > 200) {
    isPanelOpen.value = false
  } else {
    translateX.value = 0
  }
}

if (props.touchable) {
  watch([isPanelOpen, isParentPanelOpen], () => {
    translateX.value = 0
  })
}
</script>

<style scoped>
/* Для bottomToTop (slideY) */
.slideY-enter-active,
.slideY-leave-active {
  transition: transform 200ms ease;
}

.slideY-enter-from {
  transform: translateY(100%);
}

.slideY-enter-to,
.slideY-leave-from {
  transform: translateY(0);
}

.slideY-leave-to {
  transform: translateY(100%);
}

/* Для rightToLeft (slideX) */
.slideX-enter-active,
.slideX-leave-active {
  transition: transform 200ms ease-in-out;
}

.slideX-enter-from {
  transform: translateX(100%);
}

.slideX-enter-to,
.slideX-leave-from {
  transform: translateX(0);
}

.slideX-leave-to {
  transform: translateX(100%);
}

/* Для opacity (fade) */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 200ms ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}

/* Для topToBottom (slideNegativeY) */
.slideNegativeY-enter-active,
.slideNegativeY-leave-active {
  transition: transform 200ms ease;
}

.slideNegativeY-enter-from {
  transform: translateY(-100%);
}

.slideNegativeY-enter-to,
.slideNegativeY-leave-from {
  transform: translateY(0);
}

.slideNegativeY-leave-to {
  transform: translateY(-100%);
}
</style>
