<template>
  <article
    class="flex items-center bg-ne-bg-3 py-[8px] px-[20px] rounded-[8px]"
    :class="{
      'opacity-80 pointer-events-none': loading,
    }"
    @click="isMobileLayout && handleSelect()"
  >
    <div
      class="flex items-center cursor-pointer w-auto mr-ne-16 tablet:mr-ne-24 my-auto"
      @click="handleSelect"
    >
      <AppRadio
        class="pointer-events-none"
        name="bonusSelected"
        :model-value="isSelected"
        :value="1"
      ></AppRadio>
    </div>
    <!-- here will be radiobutton -->
    <div class="flex items-center justify-between grow">
      <div>
        <h4 class="mb-[1px] text-[14px] font-bold">{{ title }}</h4>
        <p class="text-[12px] max-w-[210px] tablet-s:max-w-full">
          <span class="text-ne-yellow">{{ description }}</span>
          <span
            v-if="conditions"
            class="inline-block tablet-s:ml-[12px] text-ne-neutral"
          >
            {{ conditions }}
          </span>
        </p>
      </div>
      <AppIcon v-if="iconName" :name="iconName" class="w-[24px] h-[24px]" />
    </div>
  </article>
</template>

<script setup lang="ts">
import AppRadio from '@/components/app/AppRadio.vue'
import AppIcon from '@/components/app/AppIcon.vue'
import { inject, ref } from 'vue'
import { IS_MOBILE_LAYOUT_KEY } from '@/layouts/DefaultLayout/MobileLayout/DataContext/IS_MOBILE_LAYOUT_KEY'

interface Props {
  title: string
  description: string
  isSelected: 1 | 0
  conditions?: string
  iconName?: string
  loading: boolean
}

const isMobileLayout = inject(IS_MOBILE_LAYOUT_KEY, ref(false))

const props = defineProps<Props>()
const emit = defineEmits(['handle-select'])

const handleSelect = () => {
  if (props.isSelected === 1) return
  else emit('handle-select')
}
</script>
