<template>
  <LayoutResolver class="bg-ne-bg-1 h-full w-full overflow-x-hidden">
    <router-view></router-view>
  </LayoutResolver>
</template>

<script setup lang="ts">
import { provide, ref, watch, watchEffect } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useTitle, useWindowFocus } from '@vueuse/core'
import LayoutResolver from './components/LayoutResolver.vue'

import { useInitialDataStore } from '@/stores/initialData'
import { useAuthStore } from '@/stores/auth'
import { useProfileStore } from '@/stores/profileStore'

import { RoutesNames } from '@/router'
import { useSignUpRedirect } from '@/composables/useSignUpRedirect'
import { IS_SIGNUP_DIALOG_OPEN_KEY } from '@/components/app-authentication/DataContext/IS_SIGNUP_DIALOG_OPEN_KEY'
import { useI18n } from 'vue-i18n'

const { locale } = useI18n()
const initialDataStore = useInitialDataStore()
// Load initial data and mount captcha script
initialDataStore.init().then(() => {
  const { gSiteKey } = initialDataStore

  if (gSiteKey) {
    const captchaScript = document.createElement('script')
    captchaScript.setAttribute(
      'src',
      `https://www.google.com/recaptcha/api.js?render=${gSiteKey}`
    )
    captchaScript.setAttribute('async', 'async')
    document.head.appendChild(captchaScript)
  }
})

const authStore = useAuthStore()
const profileStore = useProfileStore()
const router = useRouter()
const route = useRoute()
const title = useTitle()

// Dynamic page title update
watchEffect(() => {
  title.value = `Honey Money${route.meta?.title ? ' - ' + route.meta.title : ''}`
})

// Perform auth guard for focused tab
const isWindowFocused = useWindowFocus()
watchEffect(() => {
  if (
    !authStore.isSignedIn &&
    route.matched.some((m) => m.meta.requiresAuth) &&
    isWindowFocused.value === true
  ) {
    router.replace({ name: RoutesNames.Index })
  }

  if (
    authStore.isSignedIn &&
    route.matched.some((m) => m.meta.requiresNotAuth) &&
    isWindowFocused.value === true
  ) {
    router.replace({ name: RoutesNames.Index })
  }
})

// save a referral code
router.isReady().then(() => {
  if (route.query.stag) {
    localStorage.setItem('stag', route.query.stag as string)
  }
})

watch(
  () => profileStore.profileData?.locale,
  (value, oldValue) => {
    // reload page if user change his locale in profileData which originally comes from api (edge case when locale changed from another sessions)
    if (!!value && !!oldValue) {
      location.reload()
    }
  }
)

watch(locale, () => {
  if (!authStore.isSignedIn) {
    location.reload()
  }
})

const isSignUpDialogOpen = ref(false)
provide(IS_SIGNUP_DIALOG_OPEN_KEY, isSignUpDialogOpen)
useSignUpRedirect({ isSignUpDialogOpen })
</script>
