<template>
  <MobileNavigationElement
    :name="languageNamesMap[currentLocale]"
    submenu
    @click="isPanelOpen = true"
  >
  </MobileNavigationElement>
  <MobileFullscreenPanel
    v-model:visible="isPanelOpen"
    transition="rightToLeft"
    type="cross"
    returnable
    touchable
    @close="isMobileMainMenuOpen = false"
  >
    <h2 class="px-6 text-ne-h3 font-semibold mb-6 mt-2">
      {{ $t('dialogs.changeLanguage.title') }}
    </h2>
    <div class="px-6 flex flex-col">
      <MobileNavigationElement
        v-for="locale in availableLocales"
        :key="locale"
        :name="languageNamesMap[locale]"
        :active="locale === currentLocale"
        @click.native="onLocaleChange(locale)"
      />
    </div>
  </MobileFullscreenPanel>
</template>

<script setup lang="ts">
import MobileNavigationElement from '@/components/mobile/MobileNavigationElement.vue'
import { useI18n } from 'vue-i18n'
import { ref, toRefs } from 'vue'
import MobileFullscreenPanel from '@/layouts/DefaultLayout/MobileLayout/MobileFullscreenPanel/MobileFullscreenPanel.vue'
import { useMobileLayoutStore } from '@/stores/mobileLayout'
import { useLanguageSelector } from '@/composables/useLanguageSelector'

const languageNamesMap = {
  en: 'English',
  ru: 'Русский',
}

const isPanelOpen = ref(false)
const { isMobileMainMenuOpen } = toRefs(useMobileLayoutStore())
const { handleLocaleChange } = useLanguageSelector()

const { availableLocales, locale: currentLocale } = useI18n()

function onLocaleChange(value: string) {
  isPanelOpen.value = false
  handleLocaleChange(value)
}
</script>
