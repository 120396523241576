<template>
  <div
    class="w-full flex items-center px-[16px] py-4 cursor-pointer rounded justify-between touch-manipulation"
    :class="{ 'bg-ne-bg-3': active }"
    @click="$slots.subpage ? toggleSubmenu() : null"
  >
    <div class="flex items-center" :class="rowClass">
      <AppIcon :name="icon" width="24px" height="24px" />
      <div class="text-ne-16" :class="{ 'pl-4': icon, 'pl-ne-40': !icon }">
        {{ name }}
      </div>
      <div
        v-if="label"
        class="h-ne-24 ml-4 px-2 bg-ne-bg-1 rounded-[4px] flex justify-center items-center"
      >
        <span class="text-ne-black text-ne-12">{{ label }}</span>
      </div>
      <slot name="badge" />
    </div>

    <AppIcon v-if="submenu" name="chevron-right" width="24px" height="24px" />
    <AppIcon
      v-if="active && !submenu"
      name="mobile-navigation-success"
      width="24px"
      height="24px"
    />
    <slot name="subpage" />
  </div>
</template>

<script setup lang="ts">
import AppIcon from '@/components/app/AppIcon.vue'
import { provide, ref } from 'vue'

const isSubmenuOpen = ref(false)

function toggleSubmenu() {
  isSubmenuOpen.value = !isSubmenuOpen.value
}

defineProps({
  icon: {
    type: String,
    default: '',
  },
  name: {
    type: String,
    default: '',
  },
  rowClass: {
    type: String,
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
  submenu: {
    type: Boolean,
    default: false,
  },
  active: Boolean,
})

provide('isSubmenuOpen', isSubmenuOpen)
provide('toggleSubmenu', toggleSubmenu)
</script>
