import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useAppStateStore = defineStore('appState', () => {
  const isQuickDepositDialogOpen = ref(false)

  function toggleQuickDepositDialog() {
    isQuickDepositDialogOpen.value = !isQuickDepositDialogOpen.value
  }

  const isKolesaOtBossaBigDialogOpen = ref(true)

  function toggleKolesaOtBossaDialog() {
    isKolesaOtBossaBigDialogOpen.value = !isKolesaOtBossaBigDialogOpen.value
  }

  const isFastAndFuriousBigDialogAvailable = ref(false)

  function toggleFastAndFuriousBigDialog() {
    isFastAndFuriousBigDialogAvailable.value =
      !isFastAndFuriousBigDialogAvailable.value
  }

  const isSignInDialogOpen = ref(false)
  const isForgotPasswordDialogOpen = ref(false)
  const isNewPasswordSentConfirmationDialogOpen = ref(false)
  const isPromocodeActivationSuccessDialogOpen = ref(false)

  return {
    isQuickDepositDialogOpen,
    toggleQuickDepositDialog,
    isKolesaOtBossaBigDialogOpen,
    toggleKolesaOtBossaDialog,
    isFastAndFuriousBigDialogAvailable,
    toggleFastAndFuriousBigDialog,
    isSignInDialogOpen,
    isForgotPasswordDialogOpen,
    isNewPasswordSentConfirmationDialogOpen,
    isPromocodeActivationSuccessDialogOpen,
  }
})
