<template>
  <SwitchGroup>
    <div
      class="flex items-center"
      :class="{ 'pointer-events-none opacity-40': disabled }"
    >
      <Switch
        :id="name"
        ref="switch"
        :class="enabled ? 'bg-ne-violet-light' : 'bg-ne-violet-light/0'"
        class="relative inline-flex flex-shrink-0 h-[20px] w-[34px] border-2 border-ne-violet-light rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-ne-violet-light"
        :model-value="modelValue"
        @update:model-value="$emit('update:modelValue', $event)"
      >
        <div
          aria-hidden="true"
          :class="enabled ? 'translate-x-[14px]' : 'translate-x-0'"
          class="pointer-events-none inline-block h-[16px] w-[16px] rounded-full transform border-[2px] border-transparent transition ease-in-out duration-200"
        >
          <div
            class="w-full h-full rounded-full transition-colors ease-in-out duration-200"
            :class="{
              'bg-ne-white': enabled,
              'bg-ne-violet-light': !enabled,
            }"
          ></div>
        </div>
      </Switch>
      <SwitchLabel
        v-if="label"
        class="text-ne-14 ml-ne-10 cursor-pointer text-ne-white"
        ><slot>{{ label }}</slot></SwitchLabel
      >
    </div>
  </SwitchGroup>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'

export default defineComponent({
  name: 'AppToggle',

  components: { Switch, SwitchLabel, SwitchGroup },

  props: {
    name: {
      type: String,
      required: true,
    },
    modelValue: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:modelValue', 'blur', 'change'],

  setup(props) {
    const enabled = computed(() => props.modelValue)

    return { enabled }
  },
})
</script>
