<template>
  <div
    v-if="isMainDomain"
    id="apg-32db6a3d-8aec-4d00-95ac-2cda09bef35a"
    data-apg-seal-id="2588f43c-5dd3-4de5-8de1-475c7cd6f93e"
    data-apg-image-size="128"
    data-apg-image-type="basic-small"
  ></div>
  <button v-else @click="isMainDomainLinkDialogVisible = true">
    <img
      class="w-full h-full object-cover"
      alt="antillephone"
      :src="antillephoneImage"
    />
  </button>
  <AppDialog
    :decoration="false"
    :with-min-height="false"
    :visible="isMainDomainLinkDialogVisible"
    :title="$t('dialogs.mainDomainLink.title')"
    theme="dark"
    :width="384"
    class="min-h-[210px]"
    @close="isMainDomainLinkDialogVisible = false"
  >
    <div class="flex flex-col items-center mb-ne-32 text-center">
      <span class="text-ne-white text-ne-16 inline-block max-w-[300px]"
        >{{ $t('dialogs.mainDomainLink.text') }}
        <a href="https://honeymoney.com/" class="text-ne-link text-ne-16"
          >honeymoney.com</a
        ></span
      >
    </div>
    <div class="flex items-center justify-center">
      <AppButton
        view="primary"
        class="w-[152px]"
        text="Ok"
        @click="isMainDomainLinkDialogVisible = false"
      ></AppButton>
    </div>
  </AppDialog>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue'
import antillephoneImage from '@/assets/images/antillephone.png'
import AppDialog from '@/components/app/AppDialog.vue'
import AppButton from '@/components/app/AppButton.vue'

// eslint-disable-next-line @typescript-eslint/no-namespace
declare namespace apg_32db6a3d_8aec_4d00_95ac_2cda09bef35a {
  function init(): void
}

export default defineComponent({
  name: 'LicenseInformation',

  components: {
    AppDialog,
    AppButton,
  },

  setup() {
    const isMainDomainLinkDialogVisible = ref(false)
    const isMainDomain = location.host === 'honeymoney.com'

    onMounted(() => {
      try {
        apg_32db6a3d_8aec_4d00_95ac_2cda09bef35a?.init()
      } catch {
        //
      }
    })

    return { isMainDomainLinkDialogVisible, isMainDomain, antillephoneImage }
  },
})
</script>
