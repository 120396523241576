import { httpClient, HttpClientResponse } from '@/api/httpClient'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { toCamelCase } from '@/utils/casesConverter.js'

import { ISODateString } from '@/api/utils'
import { BonusType } from '@/types'

export interface ApiBonusesActiveResponse {
  data: {
    id: number
    title: string
    amount: string
    currency: string
    status: string
    wager_target: string
    locked_amount: string
    bets_amount: string
    created_at: ISODateString
    expiration_at: ISODateString
    wagering_type: 'real_money' | 'any_money' | null
    games?: {
      aggregator_id: number
      category_id: string
      commission: null
      cp_coefficient: number
      created_at: number
      external_id: number
      has_demo: number
      id: number
      is_desktop: number
      is_freespin: number
      is_mobile: number
      max_bet: null
      meta: null
      min_bet: null
      name: string
      pivot: {
        bonus_item_id: number
        game_id: number
      }
      bonus_item_id: number
      game_id: number
      position: number
      provider_id: number
      slug: string
      sort_order: null
      status: string
      updated_at: ISODateString
    }[]
  }[]
}

export interface BonusGame {
  aggregatorId: number
  categoryId: string
  commission: null
  cpCoefficient: number
  createdAt: number
  externalId: number
  hasDemo: number
  id: number
  isDesktop: number
  isFreespin: number
  isMobile: number
  maxBet: null
  meta: null
  minBet: null
  name: string
  pivot: {
    bonusItemId: number
    gameId: number
  }
  bonusItemId: number
  gameId: number
  position: number
  providerId: number
  slug: string
  sortOrder: null
  status: string
  updatedAt: ISODateString
}

export interface BonusCategory {
  id: number
  title: string
  bonuses: BonusListItem[]
}

export interface BonusListItem {
  id: number
  title: string
  amount: string
  currency: string
  status: string
  wagerTarget: string
  lockedAmount: string
  betsAmount: string
  bonusToCancel: string
  createdAt: ISODateString
  expirationAt: ISODateString
  ruleDepositMin: number
  multiplier?: number
  max: string | null
  type: BonusType
  games?: BonusGame[]
  bonusItemCategories?: BonusCategory[]
  countSpins?: number
  wageringType: 'real_money' | 'any_money' | null
}

export interface GetBonusesActiveResponse {
  data: BonusListItem[]
}

export const getBonusesActiveList = async (
  page: number
): Promise<GetBonusesActiveResponse> => {
  const response = await httpClient.get<
    ApiBonusesActiveResponse,
    HttpClientResponse<ApiBonusesActiveResponse>
  >('/api/bonuses/active', {
    params: {
      page: page,
    },
  })

  // // TODO validate response data
  const convertedToCamelCaseResponse = toCamelCase(
    response.data
  ) as GetBonusesActiveResponse
  return convertedToCamelCaseResponse
}
