import { defineStore } from 'pinia'
import { ref, computed, watchEffect, watch } from 'vue'

import { SignUpData, signUp } from '@/api/actions/signUp'
import { SignInData, signIn } from '@/api/actions/signIn'
import { signOut } from '@/api/actions/signOut'
import { resignIn } from '@/api/actions/resignIn'

import { useProfileStore } from '@/stores/profileStore'
import { useLocalStorageReactiveFields } from '@/composables/useLocalStorageReactiveFields'
import { useInitialDataStore } from './initialData'
import router, { RoutesNames } from '@/router'

const api = {
  signUp,
  signIn,
  signOut,
  resignIn,
}

export const useAuthStore = defineStore('auth', () => {
  const initialDataStore = useInitialDataStore()
  const profileStore = useProfileStore()

  const { accessToken, refreshToken, expiresAt } =
    useLocalStorageReactiveFields()

  const isSignedIn = computed(() => {
    return profileStore.profileData !== null && accessToken.value !== null
  })

  const isTokenValid = () => {
    const tenSeconds = 1000 * 10

    return expiresAt.value && expiresAt.value - tenSeconds > Date.now()
  }

  const signUp = async (signUpData: SignUpData) => {
    const data: SignUpData = {
      ...signUpData,
    }

    const stag = localStorage.getItem('stag')

    if (stag) {
      data.stag = stag
    }

    const res = await api.signUp(data)

    if (stag) {
      localStorage.removeItem('stag')
    }

    if (initialDataStore.gSiteKey) {
      // TODO:Move captcha function in composable
      // eslint-disable-next-line no-undef
      const token = await grecaptcha.execute(initialDataStore.gSiteKey, {
        action: 'submit',
      })
      await signIn({
        email: signUpData.email,
        password: signUpData.password,
        rememberMe: true,
        deviceId: signUpData.deviceId,
        token,
      })
    } else {
      await signIn({
        email: signUpData.email,
        password: signUpData.password,
        rememberMe: true,
        deviceId: signUpData.deviceId,
      })
    }

    return res
  }

  const signIn = async (signInData: SignInData) => {
    const res = await api.signIn(signInData)

    accessToken.value = res.accessToken
    refreshToken.value = res.refreshToken
    expiresAt.value = res.expiresAt

    if (res.twoFactor.needTwoFactor) {
      return { twoFactor: res.twoFactor }
    }
  }

  const signOut = async () => {
    try {
      await api.signOut()
    } catch {
      //
    } finally {
      clear()
      location.reload()
    }
  }

  const refreshAuth = async () => {
    const res = await api.resignIn()

    accessToken.value = res.accessToken
    refreshToken.value = res.refreshToken
    expiresAt.value = res.expiresAt
  }

  const clear = () => {
    accessToken.value = null
    refreshToken.value = null
    expiresAt.value = null

    profileStore.clearProfileData()
  }

  // TODO remove this method, сreate more clear implementation
  const goToSignUp = (promoCode?: string) => {
    //
  }

  const refreshNotificationsIntervalId = ref<NodeJS.Timer | null>(null)

  watch(isSignedIn, async () => {
    if (!isSignedIn.value) {
      await router.push({ name: RoutesNames.Index })
    }
  })

  watchEffect(() => {
    if (isSignedIn.value && !refreshNotificationsIntervalId.value) {
      profileStore.updateNotificationsCount().then(() => {
        refreshNotificationsIntervalId.value = setInterval(
          profileStore.updateNotificationsCount,
          8000
        )
      })
    } else if (!isSignedIn.value && refreshNotificationsIntervalId.value) {
      clearInterval(refreshNotificationsIntervalId.value)
    }
  })

  return {
    accessToken,
    refreshToken,
    expiresAt,
    isSignedIn,
    isTokenValid,
    signUp,
    signIn,
    signOut,
    refreshAuth,
    clear,
    goToSignUp,
  }
})
