import { httpClient, HttpClientResponse } from '@/api/httpClient'

import { ApiCommonSuccessResponse } from '@/api/utils'

import type { VipStatusName } from '@/types'

export interface ProfileApiWallet {
  id: number
  is_default: boolean
  is_main_currency: boolean
  currency: string
  balance: string
  withdrawable: string
  block_by_bonuses: string
  bonus_to_go: string
  main_currency_recalculation?: {
    balance: string
    withdrawable: string
    block_by_bonuses: string
    bonus_to_go: string
  }
}
export interface GetProfileApiResponse {
  id: number
  email: string
  nickname: string | null
  first_name: string | null
  last_name: string | null
  date_of_birth: string | null
  gender: 0 | 1 | null
  country: { id: number; name: string }
  city: string | null
  address: string | null
  phone: string | null
  subscription_by_email: 0 | 1
  subscription_by_sms: 0 | 1
  auto_accept_bonuses: 0 | 1
  default_currency: {
    id: number
    code: string
    name: string
  }
  balance: string
  main_currency: {
    id: number
    code: string
    name: string
  }
  wallets: ProfileApiWallet[]
  created_at: string
  updated_at: string
  postal_code: string | null
  is_disabled: boolean
  verification_status: 'unverified' | 'verified' | 'waiting'
  is_phone_verified: boolean
  email_verified: number
  verified_at: string | null
  self_exclusion_until: string | null

  vip: {
    cp: string
    statuses: {
      current: {
        title: VipStatusName
        min_cp: number
        max_cp: number | null
      }
      next?: {
        title: VipStatusName
        min_cp: number
        max_cp: number | null
      }
    }
  }
  google2fa_is_active: 0 | 1
  locale: string
}

export interface ProfileWallet {
  id: number
  isDefault: boolean
  isMainCurrency: boolean
  currency: string
  balance: string
  withdrawable: string
  blockByBonuses: string
  bonusToGo: string
  mainCurrencyRecalculation?: {
    balance: string
    withdrawable: string
    blockByBonuses: string
    bonusToGo: string
  }
}

export interface GetProfileResponse {
  id: number
  email: string
  nickname: string | null
  firstName: string | null
  lastName: string | null
  dateOfBirth: string | null
  gender: 0 | 1 | null
  country: { id: number; name: string }
  city: string | null
  address: string | null
  phone: string | null
  subscriptionByEmail: 0 | 1
  subscriptionBySms: 0 | 1
  autoAcceptBonuses: 0 | 1
  defaultCurrency: {
    id: number
    code: string
    name: string
  }
  balance: string
  mainCurrency: {
    id: number
    code: string
    name: string
  }
  wallets: ProfileWallet[]
  createdAt: string
  updatedAt: string
  postalCode: string | null
  isDisabled: boolean
  verificationStatus: 'unverified' | 'verified' | 'waiting'
  isPhoneVerified: boolean
  isEmailVerified: boolean
  verifiedAt: string | null
  selfExclusionUntil: string | null
  vip: {
    cp: string
    cpFloor: number
    statuses: {
      current: {
        title: VipStatusName
        minCp: number
        maxCp: number | null
      }
      next?: {
        title: VipStatusName
        minCp: number
        maxCp: number | null
      }
    }
  }
  google2faIsActive: 0 | 1
  locale: string
}

export const getProfile = async (): Promise<GetProfileResponse> => {
  const res = await httpClient
    .get<
      GetProfileApiResponse,
      HttpClientResponse<ApiCommonSuccessResponse<GetProfileApiResponse>>
    >('/api/profile')
    .then(({ data }) => data.data)

  return {
    id: res.id,
    email: res.email,
    nickname: res.nickname,
    firstName: res.first_name,
    lastName: res.last_name,
    dateOfBirth: res.date_of_birth,
    gender: res.gender,
    country: res.country,
    city: res.city,
    address: res.address,
    phone: res.phone,
    subscriptionByEmail: res.subscription_by_email,
    subscriptionBySms: res.subscription_by_sms,
    autoAcceptBonuses: res.auto_accept_bonuses,
    defaultCurrency: res.default_currency,
    balance: res.balance,
    mainCurrency: {
      id: res.main_currency.id,
      name: res.main_currency.name,
      code: res.main_currency.code,
    },
    wallets: res.wallets.map((wallet) => {
      if (wallet.main_currency_recalculation) {
        return {
          id: wallet.id,
          currency: wallet.currency,
          balance: wallet.balance,
          isDefault: wallet.is_default,
          isMainCurrency: wallet.is_main_currency,
          withdrawable: wallet.withdrawable,
          blockByBonuses: wallet.block_by_bonuses,
          bonusToGo: wallet.bonus_to_go,
          mainCurrencyRecalculation: {
            bonusToGo: wallet.main_currency_recalculation?.bonus_to_go,
            balance: wallet.main_currency_recalculation?.balance,
            blockByBonuses:
              wallet.main_currency_recalculation?.block_by_bonuses,
            withdrawable: wallet.main_currency_recalculation?.withdrawable,
          },
        }
      }
      return {
        id: wallet.id,
        currency: wallet.currency,
        balance: wallet.balance,
        isDefault: wallet.is_default,
        isMainCurrency: wallet.is_main_currency,
        withdrawable: wallet.withdrawable,
        blockByBonuses: wallet.block_by_bonuses,
        bonusToGo: wallet.bonus_to_go,
      }
    }),
    createdAt: res.created_at,
    updatedAt: res.updated_at,
    postalCode: res.postal_code,
    isDisabled: res.is_disabled,
    verificationStatus: res.verification_status,
    isPhoneVerified: res.is_phone_verified,
    isEmailVerified: res.email_verified ? true : false,
    verifiedAt: res.verified_at,
    selfExclusionUntil: res.self_exclusion_until,

    vip: {
      cp: res.vip.cp,
      cpFloor: Math.floor(+res.vip.cp),
      statuses: {
        current: {
          title: res.vip.statuses.current.title,
          minCp: res.vip.statuses.current.min_cp,
          maxCp: res.vip.statuses.current.max_cp,
        },
        next: res.vip.statuses.next && {
          title: res.vip.statuses.next.title,
          minCp: res.vip.statuses.next.min_cp,
          maxCp: res.vip.statuses.next.max_cp,
        },
      },
    },
    google2faIsActive: res.google2fa_is_active,
    locale: res.locale,
  }
}
