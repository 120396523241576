import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createI18n } from 'vue-i18n'
import { createHead } from '@vueuse/head'
import { VueSvgIconPlugin } from '@yzfe/vue3-svgicon'
import VueQrcode from '@chenfengyuan/vue-qrcode'
import Maska from 'maska'
import '@yzfe/svgicon/lib/svgicon.css'

import App from './App.vue'
import router from './router'
import './styles/index.css'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import messages from '@intlify/vite-plugin-vue-i18n/messages'

const i18n = createI18n({
  locale:
    // profileData.value?.locale ||
    // selectedLocale.value ||
    // import.meta.env.VITE_I18N_LOCALE ||
    'en',
  fallbackLocale: 'en',
  messages,
  globalInjection: true,
})

const app = createApp(App)
const head = createHead()

app.use(VueSvgIconPlugin, {
  tagName: 'vue-svg-icon',
})
app.use(i18n)
app.use(Maska)
app.use(createPinia())
app.use(router)
app.use(head)
app.component(VueQrcode.name, VueQrcode)

app.mount('#app')
