<template>
  <AppForm
    :error-message="errorMessage"
    :loading="loading"
    :submit-text="submitText"
    :hide-footer="hideFooter"
    :keep-size="isMobileLayout"
    @submit="handleSubmit"
  >
    <AppInput
      v-model.trim="fieldsData.email"
      required
      :error="validationResult.email.$invalid"
      :error-message="validationResult.email.$messages[0]"
      :placeholder="$t('dialogs.signUp.email-placeholder')"
      :disabled="disabled"
      class="mb-ne-16"
      label="E-mail"
      name="email"
      autocomplete="email"
      :light="!isMobileLayout"
    />
    <AppInput
      v-model.trim="fieldsData.password"
      required
      :error="validationResult.password.$invalid"
      :error-message="validationResult.password.$messages[0]"
      :disabled="disabled"
      placeholder="*******"
      class="mb-ne-16"
      :label="$t('dialogs.signUp.password')"
      name="password"
      type="password"
      autocomplete="current-password"
      :light="!isMobileLayout"
    />
    <div class="flex justify-between mt-ne-16">
      <AppCheckbox
        v-model="fieldsData.rememberMe"
        name="remember-me"
        :disabled="disabled"
        :light="!isMobileLayout"
        :label="$t('dialogs.login.rememberMe')"
      />
      <a
        v-if="!disabled"
        href="#"
        class="text-ne-link text-ne-14"
        @click="$emit('forgotPassword')"
      >
        {{ $t('dialogs.login.forgotPassword') }}
      </a>
    </div>
  </AppForm>
</template>

<script lang="ts">
import { defineComponent, PropType, computed, reactive, ref } from 'vue'
import useValidate from 'vue-tiny-validate'
import { z } from 'zod'
import { storeToRefs } from 'pinia'

import AppForm from '@/components/app/AppForm.vue'
import AppInput from '@/components/app/AppInput.vue'
import AppCheckbox from '@/components/app/AppCheckbox.vue'
import { useInitialDataStore } from '@/stores/initialData'

export interface EmittedLoginData {
  email: string
  password: string
  rememberMe: boolean
  deviceId: string
  token?: string
}

import { PASSWORD_MIN_LENGTH } from '@/utils/constants'

import { generateUniqueDevaiceId } from '@/utils/generateUniqueDevaiceId'
import { IS_MOBILE_LAYOUT_KEY } from '@/layouts/DefaultLayout/MobileLayout/DataContext/IS_MOBILE_LAYOUT_KEY'
import { useSafeInject } from '@/utils/useSafeInject'

export default defineComponent({
  name: 'LoginForm',

  components: { AppForm, AppInput, AppCheckbox },

  props: {
    errorMessage: {
      type: String as PropType<string | null>,
      default: null,
    },
    withCancel: {
      type: Boolean,
      default: false,
    },
    submitText: {
      type: String as PropType<string | null>,
      default: null,
    },
    loading: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['submit', 'forgotPassword'],

  setup(props, context) {
    const isMobileLayout = useSafeInject(IS_MOBILE_LAYOUT_KEY, ref(false))
    const initialDataStore = useInitialDataStore()
    const { gSiteKey } = storeToRefs(initialDataStore)

    const fieldsData = reactive({
      email: '',
      password: '',
      rememberMe: true,
    })

    const fieldsRules = computed(() => {
      const passwordCheck = (value: string) =>
        z.string().min(PASSWORD_MIN_LENGTH).safeParse(value).success
      const requiredCheck = (value: string): boolean =>
        z.string().nonempty().safeParse(value).success
      const emailCheck = (value: string) =>
        z.string().email().safeParse(value).success

      const required = {
        test: requiredCheck,
        message: 'Field is required',
        name: 'required',
      }
      const email = {
        test: emailCheck,
        message: () => 'E-mail is not correct',
        name: 'email',
      }
      const password = {
        test: passwordCheck,
        message: `Password length must be at least ${PASSWORD_MIN_LENGTH} characters`,
        name: 'password',
      }

      return {
        email: [required, email],
        password: [required, password],
      }
    })

    const { result: validationResult } = useValidate(fieldsData, fieldsRules)

    const handleSubmit = () => {
      validationResult.value.$test()

      if (!validationResult.value.$invalid) {
        if (gSiteKey.value) {
          // TODO:Move captcha function in separate file
          // eslint-disable-next-line no-undef
          grecaptcha
            .execute(gSiteKey.value, { action: 'submit' })
            .then((token) => submitData(token))
        } else {
          submitData()
        }
      }
    }

    const submitData = (token?: string) => {
      const submitedData: EmittedLoginData = {
        ...fieldsData,
        deviceId: '',
        token,
      }

      const deviceId = localStorage.getItem('deviceId')

      if (!deviceId) {
        submitedData.deviceId = generateUniqueDevaiceId()
        localStorage.setItem('deviceId', submitedData.deviceId)
      } else {
        submitedData.deviceId = deviceId
      }
      context.emit('submit', submitedData)
    }

    return { fieldsData, validationResult, handleSubmit, isMobileLayout }
  },
})
</script>
