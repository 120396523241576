<template>
  <div class="relative">
    <span class="absolute top-[37px] left-[15px] inline-block text-ne-white">
      +
    </span>
    <div :class="mainWrapperClassList">
      <label
        v-if="label"
        :for="name"
        class="block text-ne-14 mb-ne-8 text-ne-white"
      >
        <span v-if="required" class="text-ne-error">*</span>
        {{ label }}</label
      >
      <div class="relative">
        <component
          :is="'input'"
          v-bind="{
            ...$attrs,
            onInput: updateValue,
            class: '',
          }"
          :id="name"
          ref="inputRef"
          :value="modelValue"
          :type="computedInputType"
          :name="name"
          :placeholder="placeholder"
          :disabled="disabled"
          class="py-[12px] pl-[25px] pr-[16px] mb-0 text-ne-white autofill:text-ne-white bg-ne-bg-1/0 autofill:bg-ne-bg-1/0 border-2 border-ne-violet block w-full text-ne-12 placeholder-ne-neutral rounded-ne-s hover:border-ne-violet-light focus:border-ne-violet-light disabled:hover:border-ne-violet"
          :class="{
            'border-ne-error': error,
            'hover:border-ne-error': error,
            'focus:border-ne-error': error,
            'border-ne-success': success,
            'hover:border-ne-success': success,
            'focus:border-ne-success': success,
            'pr-ne-32': isTypePassword,
            'pr-ne-64': $slots.inputInner,
          }"
          :aria-describedby="
            error && errorMessage ? `${name}-error` : undefined
          "
          :aria-invalid="error && errorMessage ? true : undefined"
          @keypress="preventInputNotNumbers"
        />
        <span
          v-if="$attrs.type === 'password' || $slots.icon"
          class="absolute inset-y-0 right-ne-16 flex items-center"
        >
          <AppIcon
            v-if="$attrs.type === 'password'"
            class="cursor-pointer"
            :name="isHidePassword ? 'eye-close-16' : 'eye-open-16'"
            color="r-var(--ne-violet-light)"
            @click="togglePasswordVisibility"
          />
          <slot v-else name="icon"></slot>
        </span>
        <slot name="inputInner"></slot>
      </div>
      <p
        v-if="error && errorMessage"
        :id="`${name}-error`"
        class="mt-ne-10 text-ne-12 text-ne-error"
        aria-live="assertive"
      >
        {{ errorMessage }}
      </p>
      <p v-else-if="message" class="mt-ne-10 text-ne-12 text-gray-500">
        {{ message }}
      </p>
    </div>
  </div>
</template>
<script lang="ts">
import { SetupFormComponent } from '@/components/app/utils'
import { ref, computed, defineComponent } from 'vue'
import AppIcon from './AppIcon.vue'
const numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']

export default defineComponent({
  name: 'AppInput',

  components: {
    AppIcon,
  },

  inheritAttrs: false,

  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    modelValue: {
      type: [String, Number],
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    error: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  setup(props, context) {
    const { updateValue } = SetupFormComponent(props, context)

    const isTypePassword = computed(
      () => (context.attrs.type as string | undefined) === 'password'
    )
    const isHidePassword = ref(true)

    const togglePasswordVisibility = () =>
      (isHidePassword.value = !isHidePassword.value)

    const computedInputType = computed((): string => {
      const type = (context.attrs.type as string | undefined) || 'text'

      if (!isTypePassword.value) {
        return type
      }

      return isHidePassword.value ? 'password' : 'text'
    })

    const mainWrapperClassList = computed(() => {
      const classList = ['phone-input-component']

      if (props.disabled) {
        classList.push('opacity-40')
      }

      if (context.attrs.class) {
        classList.push(context.attrs.class as string)
      }

      return classList
    })

    const inputRef = ref<HTMLInputElement | HTMLTextAreaElement | null>(null)

    const focus = () => {
      inputRef.value?.focus()
    }

    const preventInputNotNumbers = (event: KeyboardEvent) => {
      if (!numbers.includes(event.key)) {
        event.preventDefault()
      }
    }

    return {
      updateValue,
      isTypePassword,
      computedInputType,
      isHidePassword,
      togglePasswordVisibility,

      mainWrapperClassList,

      inputRef,
      focus,
      preventInputNotNumbers,
    }
  },
})
</script>
