<template>
  <form
    class="app-form flex flex-col tablet:h-full overflow-y-auto"
    :class="{
      'h-full': !keepSize,
      'h-auto': keepSize,
    }"
    @submit.prevent="onSubmit"
  >
    <div
      class="flex flex-col justify-center"
      :class="{ 'h-full': isMobileLayout }"
    >
      <div class="app-form__body">
        <slot />
      </div>
      <p
        v-if="errorMessage"
        class="block text-ne-16 text-ne-error mb-ne-8 mt-ne-16"
      >
        {{ errorMessage }}
      </p>
      <p
        v-else-if="successMessage"
        class="block text-ne-16 text-ne-success mb-ne-8 mt-ne-16"
      >
        {{ successMessage }}
      </p>
    </div>

    <footer v-if="!hideFooter" class="mt-ne-32 flex-0">
      <slot
        name="footer"
        :submit="onSubmit"
        :disabled="disabled"
        :loading="loading"
      >
        <AppButton
          type="submit"
          view="accent"
          full
          :loading="loading"
          :disabled="disabled || loading"
          :text="submitText || $t('common.confirm')"
        />
        <AppButton
          v-if="withCancel"
          view="primary"
          full
          class="mt-ne-16"
          :disabled="disabled || loading"
          :loading="loading"
          :text="$t('common.cancel')"
          @click="$emit('cancel')"
        />
      </slot>
    </footer>
  </form>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, toRefs } from 'vue'

import AppButton from './AppButton.vue'
import { useSafeInject } from '@/utils/useSafeInject'
import { IS_MOBILE_LAYOUT_KEY } from '@/layouts/DefaultLayout/MobileLayout/DataContext/IS_MOBILE_LAYOUT_KEY'
import { useMobileLayoutStore } from '@/stores/mobileLayout'

export default defineComponent({
  name: 'AppForm',

  components: { AppButton },

  props: {
    errorMessage: {
      type: String as PropType<string | null>,
      default: null,
    },
    successMessage: {
      type: String as PropType<string | null>,
      default: null,
    },
    withCancel: {
      type: Boolean,
      default: false,
    },
    submitText: {
      type: String as PropType<string | null>,
      default: null,
    },
    loading: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
    keepSize: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['submit', 'cancel'],

  setup(props, { emit }) {
    const isMobileLayout = useSafeInject(IS_MOBILE_LAYOUT_KEY, ref(false))

    const { isMobileAuthPanelOpen } = toRefs(useMobileLayoutStore())

    const onSubmit = () => {
      emit('submit')
    }
    const onCancel = () => emit('cancel')

    return {
      onSubmit,
      onCancel,

      isMobileLayout,
      isMobileAuthPanelOpen,
    }
  },
})
</script>
