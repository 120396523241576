import { httpClient, HttpClientResponse } from '@/api/httpClient'

import {
  ApiCommonSuccessResponse,
  UnixTimestamp,
  MillisecondsTimestamp,
} from '@/api/utils'

export interface SignInData {
  email: string
  password: string
  rememberMe: boolean
  deviceId: string
  token?: string
  '2faCode'?: string
  '2faRequestId'?: string
}

export interface SignInApiRequest {
  email: string
  password: string
  remember: 1 | 0
  device_id: string
  recaptcha_token?: string
  '2fa_request_id'?: string
  '2fa_code'?: string
}

interface SignInApiResponse {
  access_token: string
  refresh_token: string
  expires_at: UnixTimestamp
  session_id: number
  two_factor: {
    need_two_factor: boolean
    type?: 'Google2FA' | 'Email'
    '2fa_request_id'?: string
    verified?: boolean
  }
}

export interface SignInResponse {
  accessToken: string
  refreshToken: string
  expiresAt: MillisecondsTimestamp
  sessionId: number
  twoFactor: {
    needTwoFactor: boolean
    type?: 'Google2FA' | 'Email'
    '2fa_request_id'?: string
    verified?: boolean
  }
}

export const signIn = async (
  signUpData: SignInData
): Promise<SignInResponse> => {
  const response = await httpClient.post<
    SignInApiResponse,
    HttpClientResponse<ApiCommonSuccessResponse<SignInApiResponse>>,
    SignInApiRequest
  >('/api/auth/signin', {
    email: signUpData.email,
    password: signUpData.password,
    remember: signUpData.rememberMe ? 1 : 0,
    device_id: signUpData.deviceId,
    recaptcha_token: signUpData.token,
    '2fa_code': signUpData['2faCode'],
    '2fa_request_id': signUpData['2faRequestId'],
  })

  return {
    accessToken: response.data.data.access_token,
    refreshToken: response.data.data.refresh_token,
    expiresAt: response.data.data.expires_at * 1000,
    sessionId: response.data.data.session_id,
    twoFactor: {
      needTwoFactor: response.data.data.two_factor.need_two_factor,
      type: response.data.data.two_factor.type,
      '2fa_request_id': response.data.data.two_factor['2fa_request_id'],
      verified: response.data.data.two_factor.verified,
    },
  }
}
