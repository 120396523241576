import { httpClient, HttpClientResponse } from '@/api/httpClient'

import { ApiCommonSuccessResponse } from '@/api/utils'
import {
  ApiAvailableForActivationBonusItem,
  AvailableForActivationBonusItem,
} from './getAvailableForActivationBonuses'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { toCamelCase } from '@/utils/casesConverter.js'

export interface SignUpData {
  email: string
  password: string
  countryId: number
  stag?: string
  locale: string
  deviceId: string
  token?: string
  promocode?: string
  phone?: string
}

export interface SignUpApiRequest {
  email: string
  password: string
  country_id: number
  stag?: string
  locale: string
  device_id: string
  recaptcha_token?: string
  promo_code?: string
  phone?: string
}

interface SignUpApiResponse {
  id: number
  email: string
  user_bonuses: ApiAvailableForActivationBonusItem[]
}

interface SignUpResponse {
  id: number
  email: string
  userBonuses: AvailableForActivationBonusItem[]
}

export const signUp = async (
  signUpData: SignUpData
): Promise<SignUpResponse> => {
  const params: SignUpApiRequest = {
    email: signUpData.email,
    password: signUpData.password,
    country_id: signUpData.countryId,
    locale: signUpData.locale,
    device_id: signUpData.deviceId,
    recaptcha_token: signUpData.token,
    promo_code: signUpData.promocode,
    phone: signUpData.phone,
  }

  if (signUpData.stag) {
    params.stag = signUpData.stag
  }

  const response = await httpClient.post<
    SignUpApiResponse,
    HttpClientResponse<ApiCommonSuccessResponse<SignUpApiResponse>>,
    SignUpApiRequest
  >('/api/auth/signup-v2', params)

  // TODO validate response data
  const convertedToCamelCaseResponse = toCamelCase(
    response.data.data
  ) as SignUpResponse

  return convertedToCamelCaseResponse
}
