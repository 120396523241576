import { httpClient, HttpClientResponse } from '@/api/httpClient'
import { PaymentMethodCode } from '@/api/types'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { toCamelCase } from '@/utils/casesConverter.js'

export type Method = {
  paymentMethod: PaymentMethodCode
  minAmount: string
  isLastUsed: boolean
}
export type GetDepositMethodsV2ApiResponse = {
  data: {
    payment_method: PaymentMethodCode
    min_amount: string
    is_last_used: boolean
  }[]
}

export type GetDepositMethodsV2Response = {
  data: Method[]
}

export type GetDepositMethodsV2ApiRequest = {
  currency: string
}
export type GetDepositMethodsV2Request = {
  currency: string
}

export const getDepositMethodsV2 = async (
  currency: string
): Promise<GetDepositMethodsV2Response> => {
  const params: GetDepositMethodsV2ApiRequest = {
    currency: currency,
  }
  const response = await httpClient.get<
    GetDepositMethodsV2ApiResponse,
    HttpClientResponse<GetDepositMethodsV2ApiResponse>
  >('/api/temp/deposit-methods-v2', {
    params,
  })

  const convertedToCamelCaseResponse = toCamelCase(
    response.data
  ) as GetDepositMethodsV2Response

  return convertedToCamelCaseResponse
}
