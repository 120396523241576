<template>
  <Popover v-slot="{ open }" class="relative">
    <PopoverButton class="flex-nowrap items-center flex">
      <div
        class="w-[42px] h-[42px] mr-ne-8 tablet:mr-ne-10 flex items-center justify-center"
      >
        <img class="w-full" alt="avatar" :src="avatarUrl" />
      </div>
      <div class="hidden desktop-s:flex text-left flex-col items-start mr-ne-8">
        <span
          class="text-ne-16 text-ne-white break-all block desktop-s:min-w-[50px] desktop-s:max-w-[225px] desktop-s:mr-ne-10"
        >
          {{ avatarName }}
        </span>
        <div class="hidden desktop-s:flex items-center">
          <span class="text-ne-12 text-ne-neutral mr-ne-8">
            {{ profileData?.vip.statuses.current.title }}
          </span>
          <template v-if="!isInGamePage">
            <div class="w-[1px] h-[10px] bg-ne-neutral mr-[6px]"></div>
            <span class="text-ne-12 text-ne-neutral">
              {{ profileData?.vip.cp }} CP
            </span>
          </template>
        </div>
      </div>
      <AppIcon
        name="open-down-16"
        color="var(--ne-violet-light)"
        class="hidden tablet:block"
        :class="[open ? 'transform rotate-180' : '']"
      />
    </PopoverButton>

    <transition
      leave-active-class="transition ease-in duration-100"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <PopoverPanel
        v-slot="{ close }"
        class="absolute right-ne-16 tablet:right-0 w-[327px] tablet:w-[300px] desktop-s:w-[300px] mt-ne-8 z-10"
      >
        <div
          class="grid grid-cols-1 justify-start bg-ne-bg-5 rounded-lg overflow-hidden tablet:pb-ne-8"
        >
          <div
            class="bg-ne-bg-4 pt-ne-16 pb-ne-24 px-ne-16 flex flex-col items-center desktop-s:hidden"
          >
            <div class="flex w-full pb-ne-8 tablet:pb-0 items-center">
              <div
                class="w-[48px] h-[48px] mr-[18px] tablet:mr-ne-10 flex flex-shrink-0 items-center justify-center"
              >
                <img class="w-full" alt="avatar" :src="avatarUrl" />
              </div>
              <div class="flex flex-col items-start mr-[10px]">
                <span
                  class="text-ne-16 text-ne-neutral leading-[23px] block text-left break-all tablet:font-bold tablet:text-ne-white desktop-s:min-w-[50px] desktop-s:max-w-[225px] desktop-s:mr-ne-10"
                >
                  {{ avatarName }}
                </span>
                <div class="flex items-center">
                  <span
                    class="text-ne-12 tablet:text-ne-14 text-ne-white mr-ne-8"
                  >
                    {{ profileData?.vip.statuses.current.title }}
                  </span>
                  <template v-if="!isInGamePage">
                    <div class="w-[1px] h-[10px] bg-ne-white mr-[6px]"></div>
                    <span class="text-ne-12 tablet:text-ne-14 text-ne-white">
                      {{ profileData?.vip.cp }} CP
                    </span>
                  </template>
                </div>
              </div>
              <div
                v-if="defaultCurrencyWallet"
                class="flex flex-col items-start ml-auto tablet:hidden"
              >
                <span class="text-ne-neutral text-ne-14">Balance</span>
                <div>
                  <span class="text-ne-white text-ne-12 mr-ne-4">
                    {{ defaultCurrencyWallet.balance }}
                  </span>
                  <span class="text-ne-white text-ne-12">
                    {{ defaultCurrencyWallet.currency }}
                  </span>
                </div>
              </div>
            </div>
            <router-link
              :to="{ name: RoutesNames.ProfileWalletDeposit }"
              class="w-full tablet:hidden"
              @click="close"
            >
              <AppButton view="accent" full :text="$t('header.deposit')" />
            </router-link>
          </div>
          <div @click="close">
            <router-link
              :to="{ name: RoutesNames.ProfileMain }"
              class="app-header__popover-item"
            >
              <AppIcon name="profile-16" color="r-var(--ne-white)" />
              <span class="ml-ne-10">{{ $t('header.menu.profile') }}</span>
            </router-link>
            <router-link
              :to="{ name: RoutesNames.ProfileWallet }"
              class="app-header__popover-item"
            >
              <AppIcon name="money-16" color="r-var(--ne-white)" />
              <span class="ml-ne-10">{{ $t('header.menu.wallet') }}</span>
            </router-link>
            <router-link
              :to="{ name: RoutesNames.ProfileVipClub }"
              class="app-header__popover-item"
            >
              <AppIcon name="stars-16" color="var(--ne-white)" />
              <span class="ml-ne-10">{{ $t('header.menu.vip-club') }}</span>
            </router-link>
            <router-link
              v-if="showExchange"
              :to="{ name: RoutesNames.ProfileExchanger }"
              class="app-header__popover-item"
            >
              <AppIcon name="exchange-16" color="var(--ne-white)" />
              <span class="ml-ne-10">{{ $t('header.menu.exchanger') }}</span>
            </router-link>
            <router-link
              :to="{ name: RoutesNames.ProfileBonusesActive }"
              class="app-header__popover-item"
            >
              <AppIcon name="gift-16" color="var(--ne-white)" />
              <span class="ml-ne-10">{{ $t('header.menu.bonuses') }}</span>
            </router-link>
            <router-link
              :to="{ name: RoutesNames.ProfileGameHistory }"
              class="app-header__popover-item"
            >
              <AppIcon name="history-16" color="r-var(--ne-white)" />
              <span class="ml-ne-10">{{ $t('header.menu.history') }}</span>
            </router-link>
            <router-link
              :to="{ name: RoutesNames.ProfileResponsibleGame }"
              class="app-header__popover-item"
            >
              <AppIcon name="eye-open-16" color="r-var(--ne-white)" />
              <span class="ml-ne-10">
                {{ $t('header.menu.responsible-game') }}
              </span>
            </router-link>
            <router-link
              :to="{ name: RoutesNames.ProfileNotifications }"
              class="app-header__popover-item"
            >
              <div class="relative">
                <div
                  v-if="profileStore.notificationsCount"
                  class="bg-ne-error rounded-full w-ne-8 h-ne-8 absolute top-ne-2 right-0"
                ></div>
                <AppIcon name="bell-16" color="r-var(--ne-white)" />
              </div>
              <span class="ml-ne-10">
                {{ $t('header.menu.notifications') }}
              </span>
            </router-link>
            <button
              class="app-header__popover-item w-full"
              @click="$emit('signOut')"
            >
              <AppIcon name="logout-16" color="r-var(--ne-white)" />
              <span class="ml-ne-10">{{ $t('header.logout') }}</span>
            </button>
          </div>
          <div
            v-if="isOutOfCasino"
            class="tablet-s:hidden bg-ne-bg-4 py-ne-16 flex justify-center"
          >
            <router-link
              :to="{ name: RoutesNames.Index }"
              class="tablet:hidden"
              @click="close"
            >
              <AppButton view="primary" :text="$t('header.back-to-casino')" />
            </router-link>
          </div>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { RoutesNames } from '@/router'

import { useProfileStore } from '@/stores/profileStore'

import AppIcon from '../app/AppIcon.vue'

import AppButton from '../app/AppButton.vue'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { useInitialDataStore } from '@/stores/initialData'

export default defineComponent({
  name: 'HeaderPopover',
  components: { Popover, PopoverButton, PopoverPanel, AppIcon, AppButton },

  props: {
    isOutOfCasino: {
      type: Boolean,
      default: true,
    },
    isInGamePage: {
      type: Boolean,
      default: true,
    },
  },

  emits: ['signOut'],

  setup() {
    const profileStore = useProfileStore()
    const initialDataStore = useInitialDataStore()

    const avatarUrl = computed(() => {
      return profileStore.profileStatusAvatarUrl
    })

    const profileData = computed(() => {
      return profileStore.profileData
    })

    const avatarName = computed(() => {
      return profileData.value?.nickname !== null
        ? profileData.value?.nickname
        : profileData.value?.email.split('@')[0]
    })

    const defaultCurrencyWallet = computed(() =>
      profileStore.profileData?.wallets.find((w) => w.isDefault)
    )

    const showExchange = computed(
      () => initialDataStore.configs?.FFFEXCHANGE === '1'
    )

    return {
      profileStore,
      profileData,
      avatarUrl,
      avatarName,
      defaultCurrencyWallet,
      RoutesNames,
      showExchange,
    }
  },
})
</script>

<style scoped>
.app-header__popover-item {
  @apply h-[40px]
  flex
  items-center
  px-[16px]
  hover:bg-ne-violet hover:bg-opacity-50
  active:bg-ne-violet
  text-left;
}
</style>
