import { defineStore } from 'pinia'
import { ref } from 'vue'

import { getInitialData } from '@/api/actions/getInitialData'
import {
  GetCountriesDisplayResponse,
  getCountriesDisplay,
} from '@/api/actions/getCountriesDisplay'
import { useRouter } from 'vue-router'
import { RoutesNames } from '@/router'

const api = { getInitialData, getCountriesDisplay }

export interface Country {
  id: number
  code: string
  name: string
  defaultCurrency: string
  availableForSignup: number
  phoneCode: string
}

export interface Currency {
  id: number
  code: string
  name: string
  decimalPlaces: number
}

export interface InitialDataState {
  isInitialDataLoaded: boolean
  countries: Country[]
  currencies: Currency[]
  countryFromIp: GetCountriesDisplayResponse | null
  gSiteKey: string | null
  configs?: {
    FFFEXCHANGE: string
    FFFPIASTRIX: string
    FFFTOURNREG: string
    FFFPHONEVERIFYFORM: string
  }
}

export const useInitialDataStore = defineStore('initialData', () => {
  const isInitialDataLoaded = ref(false)
  const countries = ref<InitialDataState['countries']>([])
  const currencies = ref<InitialDataState['currencies']>([])
  const countryFromIp = ref<InitialDataState['countryFromIp']>(null)
  const gSiteKey = ref<InitialDataState['gSiteKey']>(null)
  const configs = ref<InitialDataState['configs']>(undefined)

  const router = useRouter()

  const init = async () => {
    const [initialDataResponse, countryFromIpResponse] = await Promise.all([
      api.getInitialData(),
      api.getCountriesDisplay(),
    ])

    countries.value = initialDataResponse.countries
    currencies.value = initialDataResponse.currencies
    gSiteKey.value = initialDataResponse.gSiteKey
    configs.value = initialDataResponse?.configs
    countryFromIp.value = countryFromIpResponse
    isInitialDataLoaded.value = true

    if (!countryFromIpResponse.availableForDisplay) {
      router.push('/forbidden-country')
    } else if (
      router.currentRoute.value.name === RoutesNames.ForbiddenCountry
    ) {
      router.push('/')
    }
  }

  return {
    currencies,
    countryFromIp,
    gSiteKey,
    configs,
    isInitialDataLoaded,
    countries,
    init,
  }
})
