<template>
  <AppForm
    :error-message="errorMessage"
    :loading="loading"
    :submit-text="submitText"
    @submit="handleSubmit"
  >
    <AppInput
      v-model.trim="fieldsData.email"
      :error="validationResult.email.$invalid"
      :error-message="validationResult.email.$messages[0]"
      label="E-mail"
      name="email"
      autocomplete="new-email"
      :placeholder="$t('dialogs.signUp.email-placeholder')"
      required
      :light="!isMobileLayout"
    />
    <AppInput
      v-model="fieldsData.password"
      :error="validationResult.password.$invalid"
      :error-message="validationResult.password.$messages[0]"
      class="mt-ne-16"
      :label="$t('dialogs.signUp.password')"
      name="password"
      type="password"
      autocomplete="new-password"
      :light="!isMobileLayout"
      required
    />
    <AppInput
      v-model="fieldsData.fullName"
      class="mt-ne-16"
      :label="$t('dialogs.signUp.fullName')"
      :placeholder="$t('dialogs.signUp.fullName')"
      name="fullname"
      type="text"
      :light="!isMobileLayout"
      required
    />
    <AppInput
      v-model="fieldsData.birthDate"
      class="mt-ne-16"
      :label="$t('dialogs.signUp.birthdate')"
      :placeholder="$t('dialogs.signUp.birthdate')"
      name="birthdate"
      type="date"
      :light="!isMobileLayout"
      required
    />
    <AppCombobox
      v-if="countriesWithFlag.length"
      v-model="fieldsData.country"
      :error="validationResult.country.$invalid"
      :error-message="validationResult.country.$messages[0]"
      :items="countriesWithFlag"
      class="mt-ne-16"
      name="country"
      :label="$t('dialogs.signUp.country')"
      :light="!isMobileLayout"
      required
    >
      <template v-if="fieldsData.country" #inputImg>
        <img
          :src="getSrcFlagImgCurrent"
          :alt="fieldsData.country.code"
          class="mr-[10px] absolute top-[12px] left-[12px]"
        />
      </template>

      <template #optionImg="{ item }">
        <img :src="item.flagImg" :alt="item.code" class="mr-[10px]" />
      </template>
    </AppCombobox>

    <AppInput
      v-model="fieldsData.Address"
      class="mt-ne-16"
      :label="$t('dialogs.signUp.Address')"
      :placeholder="$t('dialogs.signUp.Address')"
      name="address"
      type="text"
      :light="!isMobileLayout"
      required
    />
    <AppPhoneInput
      v-model="fieldsData.phone"
      :error="!!validationResult.phone.$messages.length"
      :error-message="validationResult.phone.$messages[0]"
      class="mt-ne-16 phone-input-component"
      :label="$t('dialogs.signUp.phone')"
      name="phone"
      :light="!isMobileLayout"
    />
    <!--    <AppSelect-->
    <!--      v-model="fieldsData.country"-->
    <!--      :error="validationResult.country.$invalid"-->
    <!--      :error-message="validationResult.country.$messages[0]"-->
    <!--      class="mt-ne-16"-->
    <!--      :items="countries"-->
    <!--      name="country"-->
    <!--      label="Country"-->
    <!--      light-->
    <!--    />-->

    <AppToggle
      v-if="!isMobileLayout"
      v-model="isPromocodeFieldVisible"
      class="ml-auto mt-ne-16"
      name="use-promocode"
      :label="$t('dialogs.signUp.usePromocode')"
      :light="!isMobileLayout"
    ></AppToggle>

    <AppInput
      v-if="isPromocodeFieldVisible"
      v-model.trim="fieldsData.promocode"
      class="mt-ne-16"
      :label="$t('dialogs.signUp.promocode')"
      name="promocode"
      :light="!isMobileLayout"
    />

    <AppCheckbox
      v-model="fieldsData.acceptTerms"
      :error="validationResult.acceptTerms.$invalid"
      :error-message="validationResult.acceptTerms.$messages[0]"
      class="mt-ne-8"
      name="accept-terms"
      :light="!isMobileLayout"
    >
      {{ $t('dialogs.signUp.age') }}
      <router-link
        class="text-ne-link"
        :to="{ name: RoutesNames.TermsAndConditions }"
        target="_blank"
      >
        {{ $t('dialogs.signUp.terms') }}
      </router-link>
      {{ $t('dialogs.signUp.and') }}
      <router-link
        class="text-ne-link"
        :to="{ name: RoutesNames.PrivacyPolicy }"
        target="_blank"
        >{{ $t('dialogs.signUp.privacy') }}
      </router-link>
    </AppCheckbox>
  </AppForm>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  PropType,
  reactive,
  ref,
  watchEffect,
} from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'

import { Country, useInitialDataStore } from '@/stores/initialData'

import AppForm from '@/components/app/AppForm.vue'
import AppInput from '@/components/app/AppInput.vue'
import AppPhoneInput from '@/components/app/AppPhoneInput.vue'
import AppCombobox from '@/components/app/AppCombobox.vue'
import AppCheckbox from '@/components/app/AppCheckbox.vue'
import AppToggle from '@/components/app/AppToggle.vue'
import { z } from 'zod'
import useValidate from 'vue-tiny-validate'

import { RoutesNames } from '@/router'

import { PASSWORD_MIN_LENGTH } from '@/utils/constants'

import { generateUniqueDevaiceId } from '@/utils/generateUniqueDevaiceId'
import { useSafeInject } from '@/utils/useSafeInject'
import { IS_MOBILE_LAYOUT_KEY } from '@/layouts/DefaultLayout/MobileLayout/DataContext/IS_MOBILE_LAYOUT_KEY'
import { usePhoneValidation } from '@/composables/usePhoneValidation'

interface FieldsData {
  email: string
  password: string
  country: Country | null
  acceptTerms: boolean
  Address: string
  birthDate: string
  fullName: string
  promocode: string
  phone: string
}

export interface EmittedSingUpData {
  email: string
  password: string
  countryId: number
  acceptTerms: boolean
  deviceId: string
  token?: string
  promocode?: string
  phone?: string
}

export default defineComponent({
  name: 'SignUpForm',

  components: {
    AppForm,
    AppInput,
    AppCombobox,
    AppCheckbox,
    AppToggle,
    AppPhoneInput,
  },

  props: {
    errorMessage: {
      type: String as PropType<string | null>,
      default: null,
    },
    withCancel: {
      type: Boolean,
      default: false,
    },
    submitText: {
      type: String as PropType<string | null>,
      default: null,
    },
    loading: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
  },

  emits: ['submit'],

  setup(props, context) {
    const { phoneValidation, minLength, maxLength } = usePhoneValidation()

    const { t } = useI18n({ useScope: 'global' })
    const isMobileLayout = useSafeInject(IS_MOBILE_LAYOUT_KEY, ref(false))

    const initialDataStore = useInitialDataStore()
    const { countries, gSiteKey, countryFromIp } = storeToRefs(initialDataStore)

    const countriesWithFlag = computed(() => {
      const countriesWithFlag = countries.value.map((country) => {
        return {
          ...country,
          flagImg: new URL(
            `/src/assets/flags/${country.code}.svg`,
            import.meta.url
          ).href,
        }
      })
      return countriesWithFlag
    })

    const isPromocodeFieldVisible = ref(isMobileLayout.value)

    const fieldsData = reactive<FieldsData>({
      email: '',
      password: '',
      country: null,
      acceptTerms: true,
      Address: '',
      birthDate: '',
      fullName: '',
      promocode: '',
      phone: '',
    })

    const fieldsRules = computed(() => {
      const required = {
        test: (value: string | object | boolean): boolean =>
          z.string().nonempty().safeParse(value).success ||
          (typeof value === 'object' && value !== null) ||
          value === true,
        message: t('validation-errors.required'),
        name: 'required',
      }
      const email = {
        test: (value: string) => z.string().email().safeParse(value).success,
        message: t('validation-errors.email-not-correct'),
        name: 'email',
      }
      const password = {
        test: (value: string) =>
          z.string().min(PASSWORD_MIN_LENGTH).safeParse(value).success,
        message: t('validation-errors.password-length', {
          length: PASSWORD_MIN_LENGTH,
        }),
        name: 'password',
      }

      return {
        email: [required, email],
        password: [required, password],
        country: [required],
        acceptTerms: [required],
        phone:
          fieldsData.phone === fieldsData.country?.phoneCode ||
          !fieldsData.phone
            ? []
            : [phoneValidation, minLength, maxLength],
      }
    })

    const getSrcFlagImgCurrent = computed(() => {
      return new URL(
        `/src/assets/flags/${fieldsData.country?.code}.svg`,
        import.meta.url
      ).href
    })

    const { result: validationResult } = useValidate(fieldsData, fieldsRules)

    const handleSubmit = () => {
      if (fieldsData.phone) {
        fieldsData.phone = fieldsData.phone.replace(/[^\d]/g, '')
      }

      validationResult.value.$test()

      if (!validationResult.value.$invalid) {
        if (gSiteKey.value) {
          // TODO:Move captcha function in separate file
          // eslint-disable-next-line no-undef
          grecaptcha
            .execute(gSiteKey.value, { action: 'submit' })
            .then((token) => submitData(token))
        } else {
          submitData()
        }
      }
    }

    const submitData = (token?: string | undefined) => {
      const submitData: EmittedSingUpData = {
        email: fieldsData.email,
        password: fieldsData.password,
        countryId: fieldsData.country!.id,
        acceptTerms: fieldsData.acceptTerms,
        deviceId: '',
        phone:
          fieldsData.phone === fieldsData.country?.phoneCode ||
          !fieldsData.phone
            ? undefined
            : fieldsData.phone,
        token,
      }

      if (fieldsData.promocode) {
        submitData.promocode = fieldsData.promocode
      }

      const deviceId = localStorage.getItem('deviceId')

      if (!deviceId) {
        submitData.deviceId = generateUniqueDevaiceId()
        localStorage.setItem('deviceId', submitData.deviceId)
      } else {
        submitData.deviceId = deviceId
      }

      context.emit('submit', submitData)
    }

    // const init = async () => {
    //   const ipCountryFromCountriesList = countriesWithFlag.value.find(
    //     (it) => it.code === countryFromIp.value!.code
    //   )
    //   if (ipCountryFromCountriesList) {
    //     fieldsData.country = ipCountryFromCountriesList
    //   }
    // }

    watchEffect(() => {
      if (fieldsData.country) {
        fieldsData.phone = fieldsData.country.phoneCode
      }
    })

    watchEffect(() => {
      if (countryFromIp) {
        const ipCountryFromCountriesList = countriesWithFlag.value.find(
          (it) => it.code === countryFromIp.value!.code
        )
        if (ipCountryFromCountriesList) {
          fieldsData.country = ipCountryFromCountriesList
        }
      }
    })

    // init()

    return {
      countries,
      fieldsData,
      isPromocodeFieldVisible,

      handleSubmit,
      validationResult,

      RoutesNames,
      getSrcFlagImgCurrent,
      countriesWithFlag,

      isMobileLayout,
    }
  },
})
</script>
