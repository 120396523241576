<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="closeModal">
      <div class="fixed inset-0 z-50 overflow-y-auto">
        <div class="min-h-screen text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <div class="fixed inset-0 bg-ne-bg-1 bg-opacity-50" />
          </TransitionChild>

          <!-- This element is to trick the browser into centering the modal contents. -->
          <span class="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="inline-block align-middle text-left transform w-full max-w-[375px] shadow-ne-m rounded-ne-m"
              :class="{
                'tablet:max-w-[600px]': decoration,
                'tablet:max-w-[400px]': !decoration && !width,
              }"
              :style="{
                maxWidth: width && `${width}px`,
              }"
            >
              <div
                class="flex h-full flex-col tablet:flex-row tablet:items-stretch"
                :class="{
                  'tablet:min-h-[350px]': withMinHeight,
                  'tablet:min-h-auto': !withMinHeight,
                }"
              >
                <!--#region CONTENT-->
                <div
                  class="relative overflow-hidden p-ne-32 tablet:w-full bg-ne-bg-2"
                  :class="{
                    'rounded-ne-m tablet:rounded-ne-m':
                      decoration,
                    'rounded-ne-m': !decoration,
                  }"
                >
                  <DialogTitle
                    v-if="title"
                    as="h3"
                    class="text-ne-h3 font-bold text-center mb-ne-24 text-ne-white"
                  >
                    {{ title }}
                  </DialogTitle>

                  <DialogDescription
                    v-if="description"
                    as="p"
                    class="text-ne-16 text-ne-white"
                  >
                    {{ description }}
                  </DialogDescription>

                  <div
                    v-if="$slots.default"
                    class="text-ne-white"
                    :class="[title || description ? 'mt-ne-24' : '']"
                  >
                    <!-- Fix for Safari. Prevents scroll to bottom of the page after closing dialog, if first focusable element of the dialog is input-->
                    <button v-if="!visible" class="absolute transparent" />

                    <slot></slot>
                  </div>

                  <!-- the close button located here just for be positioned last in tabindex order-->
                  <button
                    class="absolute w-ne-24 h-ne-24 block top-ne-10 right-ne-10"
                    @click="closeModal"
                  >
                    <AppIcon
                      name="close-16"
                      color="var(--ne-neutral)"
                    ></AppIcon>
                  </button>
                </div>
                <!--#endregion CONTENT-->
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogDescription,
} from '@headlessui/vue'
import AppIcon from '@/components/app/AppIcon.vue'
import { defineProps, defineEmits, PropType, computed } from 'vue'

const props = defineProps({
  visible: {
    type: Boolean,
    required: true,
  },
  title: {
    type: String,
    default: null,
  },
  description: {
    type: String,
    default: null,
  },
  theme: {
    type: String as PropType<'dark' | 'light'>,
    default: 'light',
  },
  decoration: {
    type: Boolean,
    default: true,
  },
  withMinHeight: {
    type: Boolean,
    default: true,
  },
  width: {
    type: [Number, null] as PropType<number | null>,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['close', 'update:visible'])
const isOpen = computed<boolean>({
  get() {
    return props.visible
  },
  set(value) {
    emit('update:visible', value)
  },
})

const closeModal = (event: Event | boolean) => {
  // saving for backward compatibility
  if (!props.disabled) {
    emit('close', event)
    isOpen.value = false
  }
}
</script>
