import { PHONE_MAX_LENGTH, PHONE_MIN_LENGTH } from '@/utils/constants'
import { useI18n } from 'vue-i18n'
import { z } from 'zod'

export const usePhoneValidation = () => {
  const { t } = useI18n({ useScope: 'global' })
  const rgxCheck =
    (rgx: RegExp) =>
    (value: string): boolean =>
      rgx.test(value)

  const phoneValidation = {
    test: rgxCheck(/^(\s*|\d+)$/),
    message: t('validation-errors.phone-wrong'),
    name: 'phone',
  }

  const minLength = {
    test: (value: string) =>
      z.string().min(PHONE_MIN_LENGTH).safeParse(value).success,
    message: t('validation-errors.phone-min-length', {
      minLength: PHONE_MIN_LENGTH,
    }),
    name: 'minLength',
  }

  const maxLength = {
    test: (value: string) =>
      z.string().max(PHONE_MAX_LENGTH).safeParse(value).success,
    message: t('validation-errors.phone-max-length', {
      maxLength: PHONE_MAX_LENGTH,
    }),
    name: 'maxLength',
  }

  return {
    phoneValidation,
    minLength,
    maxLength,
  }
}
