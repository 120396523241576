<template>
  <div :class="{ 'opacity-40 pointer-events-none': disabled }">
    <div
      class="flex"
      :class="{
        'items-start': verticalAlignment === 'start',
        'items-center': verticalAlignment === 'center',
      }"
    >
      <input
        v-bind="{ ...$attrs, class: '' }"
        :id="name"
        :checked="modelValue"
        type="checkbox"
        class="app-checkbox"
        :class="{
          'app-checkbox--error': error,
          'app-checkbox--theme-light': light,
        }"
        :disabled="disabled"
        @change="updateValue"
      />
      <label
        v-if="label || $slots.default"
        :for="name"
        class="text-ne-14 ml-0 pl-ne-8 cursor-pointer text-ne-white"
      >
        <slot>{{ label }}</slot>
      </label>
    </div>
    <p
      v-if="error && errorMessage"
      :id="`${name}-error`"
      class="mt-ne-10 text-ne-12 text-ne-error"
      aria-live="assertive"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { SetupFormComponent } from './utils'

export default defineComponent({
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    modelValue: {
      type: Boolean,
      required: true,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    light: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    verticalAlignment: {
      type: String as PropType<'start' | 'center'>,
      default: 'start',
    },
  },

  emits: ['update:modelValue', 'blur', 'change'],

  setup(props, context) {
    const { updateValue } = SetupFormComponent(props, context)

    return {
      updateValue,
    }
  },
})
</script>
