<template>
  <section>
    <AppToggle
      v-model="useBonuses"
      :label="$t('profile.wallet.use-bonus')"
      name="useBonuses"
      :disabled="
        isBonusesLoading || isProfileLoading || isChangePriorityLoading
      "
    />
    <template v-if="showBonusList">
      <ul class="mt-ne-24">
        <li
          v-for="item in bonusesListForSelectItems"
          :key="item.id"
          class="mb-ne-16 last:mb-0"
        >
          <DepositBonusSelectItem
            :loading="isBonusesLoading || isChangePriorityLoading"
            :title="item.title"
            :description="item.description"
            :conditions="item.conditions"
            :icon-name="item.iconName"
            :is-selected="item.willBeIssued"
            @handle-select="changeBonusPriority(item.originalId)"
          />
        </li>
      </ul>

      <div class="flex items-center w-full justify-start mt-ne-16">
        <AppIcon name="info-16" color="var(--ne-violet)" class="mr-ne-10" />
        <span class="text-ne-neutral text-ne-14">{{
          $t('profile.wallet.bonus-tip')
        }}</span>
      </div>
    </template>

    <div
      v-if="showBonusSuspendNotification"
      class="flex py-ne-8 px-[12px] items-center bg-ne-brown mt-ne-16 rounded-[4px]"
    >
      <div class="mr-ne-8">
        <AppIcon name="bell-16" color="r-var(--ne-white) w-[12px] h-[12px]" />
      </div>

      <div class="text-ne-12">
        {{ $t('profile.wallet.suspend-bonus-text1') }}:
        <span
          v-for="(bonus, i) in realMoneyBonusesWithTheSameCurrency"
          :key="bonus.id"
          class="mr-ne-2"
        >
          {{ bonus.title }}
          <span v-if="i !== realMoneyBonusesWithTheSameCurrency.length - 1"
            >,</span
          >
        </span>
        {{ $t('profile.wallet.suspend-bonus-text2') }}
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { updateProfile } from '@/api/actions/updateProfile'
import { forcePriority } from '@/api/actions/updateForcePriority'
import { BonusListItemTransformed } from '../transformers/transform-bonuses-list'
import { useProfileStore } from '@/stores/profileStore'
import AppToggle from '@/components/app/AppToggle.vue'
import DepositBonusSelectItem from './DepositBonusSelectItem.vue'
import { getRejectedRequestErrorMessage } from '@/api/utils'
import AppIcon from '@/components/app/AppIcon.vue'
import {
  BonusListItem,
  getBonusesActiveList,
} from '@/api/actions/getBonusesActiveList'

const api = {
  getBonusesActiveList,
}

interface Props {
  bonusesList: BonusListItemTransformed[]
  isBonusesLoading: boolean
  isUseBonusesEnabled: boolean
  currency: string
}

const props = defineProps<Props>()

const emit = defineEmits(['need-update'])

const { t } = useI18n({ useScope: 'global' })

const useBonuses = ref(false)
const realMoneyBonuses = ref<BonusListItem[]>([])

const profileStore = useProfileStore()
const autoAcceptBonuses = computed(
  () => profileStore.profileData?.autoAcceptBonuses || props.isUseBonusesEnabled
)

watch(
  autoAcceptBonuses,
  (val) => {
    useBonuses.value = Boolean(val)
  },
  { immediate: true }
)

const bonusesListForSelectItems = computed(() => {
  return props.bonusesList.map((item, index) => {
    let description = ''

    if (item.multiplierInPercent && item.maxDeposit) {
      description = `${item.multiplierInPercent}% ${t('bonuses.up-to')} ${
        item.maxDeposit
      }`

      if (item.freespinCount) {
        description += ` + ${item.freespinCount} FS`
      }
    } else if (item.freespinCount) {
      description = `${item.freespinCount} FS`
    }

    if (item.type === 'cash' && item.amount) {
      description = `${item.amount} ${item.currency}`
    }

    return {
      ...item,
      description,
      conditions: `${t('bonuses.info.minDeposit')} ${item.minDeposit}`,
      iconName: index % 2 === 0 ? 'gift-colorful' : 'flame-colorful',
    }
  })
})

const showBonusList = computed<boolean>(() =>
  Boolean(useBonuses.value && props.bonusesList.length)
)

const showBonusSuspendNotification = computed(
  () =>
    useBonuses.value &&
    Boolean(
      bonusesListForSelectItems.value.find((bonus) => bonus.willBeIssued)
    ) &&
    realMoneyBonusesWithTheSameCurrency.value.length
)

const isProfileLoading = ref(false)
const isProfileErrorMessage = ref('')
const updateAutoAcceptBonuses = async (value: boolean) => {
  try {
    isProfileLoading.value = true

    await updateProfile({
      autoAcceptBonuses: value ? 1 : 0,
    })
    await profileStore.refreshProfile()
  } catch (e) {
    isProfileErrorMessage.value = getRejectedRequestErrorMessage(e)
  } finally {
    isProfileLoading.value = false
  }
}

const isChangePriorityLoading = ref(false)
const isChosePriorityErrorMessage = ref('')
const changeBonusPriority = async (bonusOriginalId: number) => {
  try {
    isChangePriorityLoading.value = true

    await forcePriority(bonusOriginalId, props.currency)

    emit('need-update')
  } catch (e) {
    isChosePriorityErrorMessage.value = getRejectedRequestErrorMessage(e)
  } finally {
    isChangePriorityLoading.value = false
  }
}

const getActiveBonusesList = async (page = 1) => {
  const { data: bonuses } = await api.getBonusesActiveList(page)
  realMoneyBonuses.value = bonuses.filter(
    (bonus) => bonus.wageringType === 'real_money'
  )
}

const realMoneyBonusesWithTheSameCurrency = computed(() =>
  realMoneyBonuses.value.filter((bonus) => bonus.currency === props.currency)
)

const init = async () => {
  await getActiveBonusesList()
}

init()

watch(
  useBonuses,
  (val) => {
    if (val !== Boolean(autoAcceptBonuses.value)) {
      updateAutoAcceptBonuses(val)
    }

    // if (val && !props.bonusesList.length) {
    //   emit('need-update')
    // }
  },
  { immediate: true }
)
</script>
