import { httpClient, HttpClientResponse } from '@/api/httpClient'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { toCamelCase } from '@/utils/casesConverter.js'

import { ISODateString } from '@/api/utils'
import { BonusType } from '@/types'

interface ApiBonusGame {
  id: number
  slug: string
  name: string
  has_demo: number
  image: string // deprecated
  image_url: string
  provider: {
    id: number
    slug: string
    name: string
    logo: string // deprecated
    logo_url: string
  }
}

interface ApiBonusBaseInfo {
  amount: number | null
  count_spins: number | null
  max: string | null
  id: number
  currency: string
  wager: number
  type: BonusType
  multiplier: number | null
  title: string
  rule_deposit_count: number | null
  rule_deposit_min: number | null
  priority: number | null
  games: ApiBonusGame[]
  created_at: ISODateString
  updated_at: ISODateString
  activation_period: number
  required_email: 1 | 0
  required_phone: 1 | 0
}

interface ApiBonusItemCategory {
  bonuses: ApiBonusBaseInfo[]
  id: number
  title: string
}
interface ApiAvailableBonusItem extends ApiBonusBaseInfo {
  available_now: 1 | 0
  bonus_item_categories: ApiBonusItemCategory[]
  will_be_issued: 1 | 0
}

interface AvailableBonusesApiResponse {
  data: ApiAvailableBonusItem[]
}

export interface AvailableBonusesApiRequest {
  only_next?: 0 | 1
  currency?: string
}

export interface BonusGame {
  id: number
  slug: string
  name: string
  hasDemo: number
  image: string // deprecated
  imageUrl: string
  provider: {
    id: number
    slug: string
    name: string
    logo: string // deprecated
    logoUrl: string
  }[]
}

export interface BonusBaseInfo {
  amount: number | null
  countSpins: number | null
  max: string | null
  id: number
  currency: string
  wager: number
  type: BonusType
  multiplier: number | null
  title: string
  ruleDepositCount: number | null
  ruleDepositMin: string | null
  priority: number | null
  games: BonusGame[]
  createdAt: ISODateString
  updatedAt: ISODateString
  activationPeriod: number
  requireEmail: 1 | 0
  requirePhone: 1 | 0
}
export interface BonusItemCategory {
  bonuses: BonusBaseInfo[]
  id: number
  title: string
}

export interface AvailableBonusItem extends BonusBaseInfo {
  availableNow: 1 | 0
  bonusItemCategories: BonusItemCategory[]
  willBeIssued: 1 | 0
}

export interface AvailableBonusesResponse {
  data: AvailableBonusItem[]
}

export interface AvailableBonusesRequest {
  onlyNext?: 0 | 1
  currency?: string
}

export const getAvailableBonusesList = async (
  data?: AvailableBonusesRequest
): Promise<AvailableBonusesResponse> => {
  const params: AvailableBonusesApiRequest = {}

  if (data?.onlyNext) {
    params.only_next = data.onlyNext
  }

  if (data?.currency) {
    params.currency = data.currency
  }

  const response = await httpClient.get<
    AvailableBonusesApiResponse,
    HttpClientResponse<AvailableBonusesApiResponse>
  >('/api/bonuses/available', {
    params,
  })

  // // TODO validate response data
  const convertedToCamelCaseResponse = toCamelCase(
    response.data
  ) as AvailableBonusesResponse
  return convertedToCamelCaseResponse
}
