import { Ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useMobileLayoutStore } from '@/stores/mobileLayout'
import { storeToRefs } from 'pinia'
import { RoutesNames } from '@/router'
import { useAuthStore } from '@/stores/auth'

export function useSignUpRedirect({
  isSignUpDialogOpen,
}: {
  isSignUpDialogOpen: Ref<boolean>
}) {
  const route = useRoute()
  const router = useRouter()

  const authStore = useAuthStore()
  const { isMobileAuthPanelOpen } = storeToRefs(useMobileLayoutStore())
  const { isSignedIn } = storeToRefs(useAuthStore())

  function openSignupDialog() {
    isMobileAuthPanelOpen.value = true
    isSignUpDialogOpen.value = true
  }

  function redirectToSignUp(promoCode?: string) {
    openSignupDialog()
    router.replace({
      query: {
        ...(route.query || {}),
        signUp: '1',
        promoCode,
      },
    })
  }

  function redirectBack() {
    router.replace({
      query: {
        ...(route.query || {}),
        signUp: undefined,
        promoCode: undefined,
      },
    })
  }

  function redirectToProfileWalletDeposit(promoCode: string) {
    router.push({
      name: RoutesNames.ProfileWalletDeposit,
      query: { promoCode },
    })
  }

  watch(
    () => route.query?.promoCode,
    (promoCode) => {
      if (promoCode) {
        if (isSignedIn.value) {
          redirectToProfileWalletDeposit(promoCode as string)
        } else {
          if (promoCode) {
            redirectToSignUp(promoCode as string)
          }
        }
      }
    },
    { immediate: true }
  )

  watch(
    () => route.query.signUp,
    (signUp) => {
      if (signUp) {
        openSignupDialog()
      }
    },
    {
      immediate: true,
    }
  )

  watch(
    () => isSignUpDialogOpen.value,
    (isOpen) => {
      if (isOpen) {
        requestAnimationFrame(() => {
          redirectToSignUp(route.query.promoCode as string)
        })
      } else {
        redirectBack()
      }
    }
  )

  watch(
    () => isMobileAuthPanelOpen.value,
    (isOpen) => {
      if (isOpen) {
        requestAnimationFrame(() => {
          redirectToSignUp()
        })
      } else {
        redirectBack()
      }
    }
  )

  authStore.$onAction((actionContext: any) => {
    if (actionContext.name === 'goToSignUp') {
      redirectToSignUp(actionContext.args[0])
    }
  })
}
