<template>
  <div
    v-if="game.tags?.length"
    class="absolute flex flex-row gap-ne-8 top-0 left-0"
  >
    <div
      v-if="checkIfGameTaggedBy(game, 'new')"
      class="w-[44px] h-[24px] bg-ne-yellow text-ne-bg-1 rounded flex items-center justify-center font-[400] text-ne-12"
    >
      New
    </div>
    <div
      v-if="checkIfGameTaggedBy(game, 'hot')"
      class="w-[24px] h-[24px] bg-rose-700 rounded"
    >
      <AppIcon name="tag-hot" />
    </div>
  </div>
</template>

<script setup lang="ts">
import AppIcon from '@/components/app/AppIcon.vue'
import { Game } from '@/api/actions/getGamesList'
import { PropType } from 'vue'

defineProps({
  game: {
    type: Object as PropType<Game>,
    required: true,
  },
})

function checkIfGameTaggedBy(game: Game, tagName: string) {
  return !!game.tags.find((tag) => tag.slug === tagName)
}
</script>
