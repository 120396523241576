<template>
  <component
    :is="resolvedDialog"
    v-model:visible="isQuickDepositDialogOpen"
    v-bind="{ ...appDialogProps, ...mobilePanelProps }"
  >
    <DepositWalletPage class="text-white p-6 overflow-y-auto h-full" />
  </component>
</template>

<script setup lang="ts">
import MobileFullscreenPanel from '@/layouts/DefaultLayout/MobileLayout/MobileFullscreenPanel/MobileFullscreenPanel.vue'
import { useAppStateStore } from '@/stores/appStateStore'
import AppDialog from '@/components/app/AppDialog.vue'
import { inject, provide, ref } from 'vue'
import { IS_MOBILE_LAYOUT_KEY } from '@/layouts/DefaultLayout/MobileLayout/DataContext/IS_MOBILE_LAYOUT_KEY'
import { storeToRefs } from 'pinia'
import DepositWalletPage from '@/pages/ProfilePage/child-pages/WalletPage/child-pages/DepositWalletPage.vue'
import { IS_QUICK_DEPOSIT_DIALOG } from '@/components/dialogs/QuickDepositDialog/DataContext/IS_QUICK_DEPOSIT_DIALOG'

const appDialogProps = {
  theme: 'dark',
  decoration: false,
  'with-min-height': false,
  width: 600,
}

const mobilePanelProps = {
  type: 'cross',
  transition: 'opacity',
  hideBottomNavbar: true,
}

const isMobileLayout = inject(IS_MOBILE_LAYOUT_KEY, ref(false))
provide(IS_QUICK_DEPOSIT_DIALOG, ref(true))
const { isQuickDepositDialogOpen } = storeToRefs(useAppStateStore())

const resolvedDialog = isMobileLayout.value ? MobileFullscreenPanel : AppDialog
</script>
