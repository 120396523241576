<script setup lang="ts">
import MobileNavigationElement from '@/components/mobile/MobileNavigationElement.vue'
import { useRouter } from 'vue-router'
import { RoutesNames } from '@/router'
import { defineAsyncComponent, h, toRefs } from 'vue'
import { useAuthStore } from '@/stores/auth'
import { useProfileStore } from '@/stores/profileStore'
import { useI18n } from 'vue-i18n'
import { useMobileLayoutStore } from '@/stores/mobileLayout'

const router = useRouter()
const { t } = useI18n()

const { isMobileProfilePanelOpen } = toRefs(useMobileLayoutStore())

const { notificationsCount } = toRefs(useProfileStore())
const { signOut } = useAuthStore()

interface NavigationElement {
  icon: string
  name: string
  handler?: () => void
  badge?: ReturnType<typeof defineAsyncComponent>
  submenu?: boolean
  submenuComponent?: ReturnType<typeof defineAsyncComponent>
}

const navigation: NavigationElement[] = [
  {
    icon: 'mobile-navigation-notification',
    name: t('mobile-profile-menu.notifications'),
    badge: () =>
      notificationsCount.value
        ? h(
            'div',
            {
              class:
                'ml-4 bg-ne-error px-2 text-white rounded-full text-ne-12 flex justify-center items-center h-[22px]',
            },
            notificationsCount.value
          )
        : null,
    submenu: true,
    submenuComponent: defineAsyncComponent(
      () =>
        import(
          '@/layouts/DefaultLayout/MobileLayout/MobileAppHeader/MobileProfileMenu/MobileNotificationsMenu.vue'
        )
    ),
  },
  {
    icon: 'plus-24',
    name: t('mobile-profile-menu.profile-info'),
    handler() {
      router.push({ name: RoutesNames.Profile })
      isMobileProfilePanelOpen.value = false
    },
  },
  {
    icon: 'mobile-navigation-payments',
    name: t('mobile-profile-menu.wallet'),
    handler() {
      router.push({ name: RoutesNames.ProfileWallet })
      isMobileProfilePanelOpen.value = false
    },
  },
  {
    icon: 'exchange-16',
    name: t('mobile-profile-menu.exchange'),
    handler() {
      router.push({ name: RoutesNames.ProfileExchanger })
      isMobileProfilePanelOpen.value = false
    },
  },
  {
    icon: 'mobile-navigation-promotion',
    name: t('mobile-profile-menu.bonuses'),
    handler() {
      router.push({ name: RoutesNames.ProfileBonusesAll })
      isMobileProfilePanelOpen.value = false
    },
  },
  {
    icon: 'mobile-navigation-vip-club',
    name: t('mobile-profile-menu.vip-club'),
    handler() {
      router.push({ name: RoutesNames.ProfileVipClub })
      isMobileProfilePanelOpen.value = false
    },
  },
  {
    icon: 'mobile-navigation-time',
    name: t('mobile-profile-menu.game-history'),
    handler() {
      router.push({ name: RoutesNames.ProfileGameHistory })
      isMobileProfilePanelOpen.value = false
    },
  },
  {
    icon: 'mobile-navigation-document',
    name: t('mobile-profile-menu.responsible-game'),
    handler() {
      router.push({ name: RoutesNames.ProfileResponsibleGame })
      isMobileProfilePanelOpen.value = false
    },
  },
  {
    icon: 'logout',
    name: t('mobile-profile-menu.logout'),
    handler() {
      isMobileProfilePanelOpen.value = false
      signOut()
    },
  },
]
</script>

<template>
  <div class="flex flex-col">
    <MobileNavigationElement
      v-for="element of navigation"
      :key="element.name"
      :icon="element.icon"
      :name="element.name"
      :submenu="element.submenu"
      @click="element.handler && element.handler()"
    >
      <template v-if="element.badge" #badge>
        <component :is="element.badge" />
      </template>

      <template v-if="element.submenuComponent" #subpage>
        <component :is="element.submenuComponent" />
      </template>
    </MobileNavigationElement>
  </div>
</template>
