<template>
  <div class="flex flex-col items-center">
    <img
      src="/src/assets/images/baraban_crown.png"
      alt="baraban crown"
      class="mb-ne-24 w-[174px] h-[202px]"
    />
    <h2 class="mb-ne-16 text-ne-yellow text-ne-h2">
      {{ $t('dialogs.promocodeActivationSuccess.promocode') }}
    </h2>
    <p class="text-ne-white mb-ne-32 text-center text-ne-14">
      {{ $t('dialogs.promocodeActivationSuccess.text') }}
    </p>
    <AppButton
      view="primary"
      :text="$t('dialogs.promocodeActivationSuccess.button')"
      @click="handleClick"
    />
  </div>
</template>

<script setup lang="ts">
import AppButton from '@/components/app/AppButton.vue'
import router from '@/router'
const emit = defineEmits(['close'])

const handleClick = () => {
  emit('close')
  router.push({
    path: '/profile/bonuses/all',
  })
}
</script>
