import BtcCoin40 from '@/assets/svg-icons/wallet-coins/40_btc.svg?url'
import EthCoin40 from '@/assets/svg-icons/wallet-coins/40_eth.svg?url'
import EurCoin40 from '@/assets/svg-icons/wallet-coins/40_eur.svg?url'
import RubCoin40 from '@/assets/svg-icons/wallet-coins/40_rub.svg?url'
import UsdCoin40 from '@/assets/svg-icons/wallet-coins/40_usd.svg?url'
import BtcCoin32 from '@/assets/svg-icons/wallet-coins/32_btc.svg?url'
import EthCoin32 from '@/assets/svg-icons/wallet-coins/32_eth.svg?url'
import EurCoin32 from '@/assets/svg-icons/wallet-coins/32_eur.svg?url'
import RubCoin32 from '@/assets/svg-icons/wallet-coins/32_rub.svg?url'
import UsdCoin32 from '@/assets/svg-icons/wallet-coins/32_usd.svg?url'
import BtcCoin24 from '@/assets/svg-icons/wallet-coins/24_btc.svg?url'
import EthCoin24 from '@/assets/svg-icons/wallet-coins/24_eth.svg?url'
import EurCoin24 from '@/assets/svg-icons/wallet-coins/24_eur.svg?url'
import RubCoin24 from '@/assets/svg-icons/wallet-coins/24_rub.svg?url'
import UsdCoin24 from '@/assets/svg-icons/wallet-coins/24_usd.svg?url'

import BtcMethod from '@/assets/svg-icons/wallet-coins/btc.svg?url'
import CardMethod from '@/assets/svg-icons/wallet-coins/card.svg?url'
import EthMethod from '@/assets/svg-icons/wallet-coins/eth.svg?url'
import EurMethod from '@/assets/svg-icons/wallet-coins/eur.svg?url'
import LtcMethod from '@/assets/svg-icons/wallet-coins/ltc.svg?url'
import PiastrixMethod from '@/assets/svg-icons/wallet-coins/piastrix.svg?url'
import RubMethod from '@/assets/svg-icons/wallet-coins/rub.svg?url'
import UsdMethod from '@/assets/svg-icons/wallet-coins/usd.svg?url'
import UsdtErcMethod from '@/assets/svg-icons/wallet-coins/usdt_erc.svg?url'
import UsdcErcMethod from '@/assets/svg-icons/wallet-coins/usdc_erc.svg?url'
import UsdcTrcMethod from '@/assets/svg-icons/wallet-coins/usdc_trc.svg?url'
import UsdtTrcMethod from '@/assets/svg-icons/wallet-coins/usdt_trc.svg?url'

export const PASSWORD_MIN_LENGTH = 8

export const MediaQueryMinWidth = {
  TABLET: 768,
  DESKTOP_S: 1170,
  DESKTOP: 1280,
}

export const PHONE_MIN_LENGTH = 7

export const PHONE_MAX_LENGTH = 13

export const currencyImages24 = {
  RUB: RubCoin24,
  BTC: BtcCoin24,
  ETH: EthCoin24,
  EUR: EurCoin24,
  USD: UsdCoin24,
}
export const currencyImages32 = {
  RUB: RubCoin32,
  BTC: BtcCoin32,
  ETH: EthCoin32,
  EUR: EurCoin32,
  USD: UsdCoin32,
}
export const currencyImages40 = {
  RUB: RubCoin40,
  BTC: BtcCoin40,
  ETH: EthCoin40,
  EUR: EurCoin40,
  USD: UsdCoin40,
}

export const paymentMethodsMockIcons = {
  RUB: RubMethod,
  BTC: BtcMethod,
  ETH: EthMethod,
  EUR: EurMethod,
  USD: UsdMethod,
  BANK: CardMethod,
  USDT_ERC20: UsdtErcMethod,
  USDC: UsdcErcMethod,
  TRX_USDC_6NU3: UsdcTrcMethod,
  LTC: LtcMethod,
  TRX_USDT_S2UZ: UsdtTrcMethod,
  PIASTRIX_RUB: PiastrixMethod,
}

export const DAYS_OF_WEEK = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]
