import { defineStore } from 'pinia'

import { PaginationLinks, PaginationMeta } from '@/api/types'
import { Serializer, useLocalStorage } from '@vueuse/core'

interface BaseGame {
  id: number
  slug: string
  name: string
  image: string // deprecated
  imageUrl: string
  hasDemo: boolean
  tags: {
    id: number
    name: string
    slug: string
  }[]
}

interface Provider {
  id: number
  slug: string
  name: string
  logo: string // deprecated
  logoUrl: string
}

export interface Game extends BaseGame {
  provider: Provider
}

export interface SearchListGame extends BaseGame {
  provider: string
}

const objectSerializer: Serializer<any> = {
  read: (val) => JSON.parse(val),
  write: (val) => JSON.stringify(val),
}

export const useGamesCatalogStore = defineStore('gamesCatalog', () => {
  const gamesList = useLocalStorage<Game[]>(`gamesList`, [])
  const gamesLinks = useLocalStorage<PaginationLinks | null>(
    `gamesLinks`,
    null,
    {
      serializer: objectSerializer,
    }
  )
  const gamesMeta = useLocalStorage<PaginationMeta | null>(`gamesMeta`, null, {
    serializer: objectSerializer,
  })
  const providers = useLocalStorage<Provider[]>(`providers`, [])
  const selectedProvider = useLocalStorage<number | null>(
    `selectedProvider`,
    null,
    {
      serializer: {
        read(raw: string) {
          return Number(JSON.parse(raw))
        },
        write(val: any) {
          return JSON.stringify(val)
        },
      },
    }
  )
  const groupFilterTag = useLocalStorage<string>(`groupFilterTag`, '')
  const allExistedGames = useLocalStorage<SearchListGame[] | null>(
    `allExistedGames`,
    null,
    {
      serializer: objectSerializer,
    }
  )

  const clearStoredData = () => {
    gamesList.value = []
    gamesLinks.value = null
    gamesMeta.value = null
    providers.value = []
    selectedProvider.value = null
    groupFilterTag.value = ''
    allExistedGames.value = null
  }

  return {
    gamesList,
    gamesLinks,
    gamesMeta,
    providers,
    selectedProvider,
    groupFilterTag,
    allExistedGames,
    clearStoredData,
  }
})
